import { ZoneElement } from "../../../models/inspection/v2/zoneElement.model";
import { BaseDto } from "../../../../gyzmo-commons/dtos/base/base.dto";
import { DamageCategoryDto } from "../common/damageCategory.dto";

export class ZoneElementDto extends BaseDto<ZoneElement> {
    public id: string = "";
    public parentZoneId: string = "";
    public wording: string = "";
    public filter: string = "";
    public filter2: string = "";
    public order: string = "";

    public damageCategory: DamageCategoryDto;

    constructor() {
        super();

        this.damageCategory = new DamageCategoryDto();
    }

    static fromBody(body: any, parentZoneId: string): ZoneElementDto {
        let elementDto = new ZoneElementDto();

        elementDto.id = "" + body.id;
        elementDto.parentZoneId = parentZoneId;
        elementDto.wording = body.wording;
        elementDto.filter = body.filter;
        elementDto.filter2 = body.filter2;
        elementDto.order = body.order;

        if (body.damageCategory) {
            elementDto.damageCategory = DamageCategoryDto.fromBody(body.damageCategory);
        }

        return elementDto;
    }

    static fromModel(element: ZoneElement) {
        let elementDto = new ZoneElementDto();

        elementDto.id = element.id;
        elementDto.parentZoneId = element.parentZoneId;
        elementDto.wording = element.wording;
        elementDto.filter = element.filter;
        elementDto.filter2 = element.filter2;
        elementDto.order = element.order;

        if (element.damageCategory) {
            elementDto.damageCategory = DamageCategoryDto.fromModel(element.damageCategory);
        }

        return elementDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.damageCategory = this.damageCategory.toBody();

        return clone;
    }

    public toModel(): ZoneElement {
        let element = new ZoneElement();

        element.id = this.id;
        element.parentZoneId = this.parentZoneId;
        element.wording = this.wording;
        element.filter = this.filter;
        element.filter2 = this.filter2;
        element.order = this.order;

        if (this.damageCategory) {
            element.damageCategory = this.damageCategory.toModel();
        }

        return element;
    }
}
