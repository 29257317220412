import { Injectable } from "@angular/core";
import { MaintenanceControlDto } from "../../dto/maintenanceControl.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class MaintenanceControlsWsDao extends WsDao<MaintenanceControlDto> {
    static readonly WS = "maintenance-controls";

    public listByEquipment(serverConnection: ServerConnection, equipmentId: string): Promise<MaintenanceControlDto[]> {
        return new Promise<MaintenanceControlDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("equipmentId", equipmentId);

            serverConnection.get(this.constructor.name, MaintenanceControlsWsDao.WS + "/:equipmentId?_limit=NOLIMIT", tokens)
                .then(response => {
                    let result: MaintenanceControlDto[] = [];
                    if (response.body instanceof Array) {
                        (response.body as Array<any>).forEach(value => {
                            let maintenanceControlDto = MaintenanceControlDto.fromBody(value, equipmentId);
                            result.push(maintenanceControlDto);
                        });
                    }

                    result = result.toSorted((a, b) => {
                        return a.order - b.order;
                    });
                    resolve(result);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<MaintenanceControlDto> {
        throw new Error("Not implemented");
    }

    public save(serverConnection: ServerConnection, maintenanceControl: MaintenanceControlDto): Promise<MaintenanceControlDto> {
        throw new Error("Not implemented");
    }
}
