import { LogLevel } from "../gyzmo-commons/interfaces/logLevel";
import { EnvironmentSpec } from "./environment.model.specif";

export const environment: EnvironmentSpec = {
    production: false,
    customer: "SAAS",
    defaultLogin: "VEGA",
    defaultPassword: "VEGAGCSI",
    mockCamera: false,
    mocked: true,
    servers: [
        {
            name: "Gyzmo Dev",
            url: "https://api.gyzmo-technology.net/api-ihubcheckplus-recette",
            apiKey: "aee1a99e-7900-41b7-ba08-a2f1b64cc891",
            defaultToken: "KY_MOBILITE",
            token: "KY_MOBILITE",
            context: "IHUBCHECKPLUS_RECETTE",
        },
    ],
    logging: {
        level: LogLevel.DEBUG,
        consoleLogger: { active: true },
        fileLogger: { active: false },
    },
};
