import { Injectable } from "@angular/core";
import { ZoneElementDbDaoV2 } from "../../../dao/db/inspection/v2/zoneElement.db.dao";
import { ZoneElementDto } from "../../../dto/inspection/v2/zoneElement.dto";

@Injectable({
    providedIn: "root",
})
export class ZoneElementService {
    constructor(private zoneElementDbDaoV2: ZoneElementDbDaoV2) {
    }

    public async getList(): Promise<ZoneElementDto[]> {
        return (await this.zoneElementDbDaoV2.getList()).map(value => {
            return ZoneElementDto.fromModel(value);
        });
    }

    public async getByZoneId(zoneId: string): Promise<ZoneElementDto[]> {
        return (await this.zoneElementDbDaoV2.getByZoneId(zoneId)).map(value => {
            return ZoneElementDto.fromModel(value);
        });
    }
}
