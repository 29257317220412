import { Injectable } from "@angular/core";
import { NativeBiometric } from "@capgo/capacitor-native-biometric";
import { BiometryType } from "@capgo/capacitor-native-biometric/dist/esm/definitions";
import { TranslateService } from "@ngx-translate/core";
import { KeyValueDbDao } from "../dao/db/keyValue.db.dao";
import { DeviceHelper } from "../helpers/device.helper";
import { KeyValue } from "../models/keyValue.model";

@Injectable({
    providedIn: "root",
})
export class BiometryService {

    constructor(private keyValueDbDao: KeyValueDbDao,
                private translateService: TranslateService,
                private deviceHelper: DeviceHelper) {
    }

    public initialize(): Promise<void> {
        // Nothing to do
        return Promise.resolve();
    }

    public isAvailable(): Promise<{ isAvailable: boolean, biometryType: BiometryType; }> {
        if (this.deviceHelper.isRunningOnDevice()) {
            return NativeBiometric.isAvailable();
        }

        return Promise.resolve({ isAvailable: false, biometryType: BiometryType.NONE });
    }

    public async isActive(): Promise<boolean> {
        let biometricActivated = await this.keyValueDbDao.get("BiometricActivated");
        if (biometricActivated) {
            return biometricActivated.value == "1";
        }

        return null;
    }

    public async setActive(active?: boolean) {
        if (active == null) {
            await this.keyValueDbDao.delete("BiometricActivated");
        } else {
            await this.keyValueDbDao.save(new KeyValue("BiometricActivated", active ? "1" : "0"));
        }
    }

    public async authenticate(): Promise<boolean> {
        const verified = await NativeBiometric.verifyIdentity({
            title: this.translateService.instant("IONIC_Authentication_required"),
            subtitle: this.translateService.instant("IONIC_Verification_of_your_identity"),
            description: this.translateService.instant("IONIC_Perform_biometric_authentication"),
            negativeButtonText: this.translateService.instant("IONIC_Cancel"),
        })
            .then(() => true)
            .catch(() => false);

        return verified;
    }

    public async setCredentials(server: string, login: string, password: string) {
        await this.keyValueDbDao.save(new KeyValue(server, JSON.stringify({ login: login, password: password })));
    }

    public async getCredentials(server: string): Promise<{ login: string, password: string }> {
        let credentialsKV = await this.keyValueDbDao.get(server);
        if (credentialsKV) {
            let credentials = JSON.parse(credentialsKV.value);
            return { login: credentials.login, password: credentials.password };
        } else {
            throw new Error("No credentials for server " + server);
        }
    }

    public deleteCredentials(server: string) {
        return this.keyValueDbDao.delete(server);
    }
}
