import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";
import { AttachedDocument } from "../models/attachedDocument.model";

export class AttachedDocumentDto extends BaseDto<AttachedDocument> {
    public id: string = "";
    public wording: string = "";
    public file: string = "";

    static fromBody(body: any): AttachedDocumentDto {
        let attachedDocumentDto = new AttachedDocumentDto();

        attachedDocumentDto.id = "" + body.id;
        attachedDocumentDto.wording = body.wording;
        attachedDocumentDto.file = body.file;

        return attachedDocumentDto;
    }

    public static fromModel(model: AttachedDocument): AttachedDocumentDto {
        let attachedDocumentDto = new AttachedDocumentDto();

        attachedDocumentDto.id = model.id;
        attachedDocumentDto.wording = model.wording;
        attachedDocumentDto.file = model.file;

        return attachedDocumentDto;
    }

    public static fromModels(models: AttachedDocument[]): AttachedDocumentDto[] {
        let attachedDocumentDtos = [];

        models.forEach(model => {
            attachedDocumentDtos.push(this.fromModel(model));
        });

        return attachedDocumentDtos;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): AttachedDocument {
        let attachedDocument = new AttachedDocument();

        attachedDocument.id = this.id;
        attachedDocument.wording = this.wording;
        attachedDocument.file = this.file;

        return attachedDocument;
    }

    public clone(): AttachedDocumentDto {
        let clone = new AttachedDocumentDto();

        clone.id = this.id;
        clone.wording = this.wording;
        clone.file = this.file;

        return clone;
    }
}
