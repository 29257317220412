import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy, Renderer2 } from "@angular/core";
import { DomController } from "@ionic/angular";
import { takeWhile } from "rxjs/operators";

const TRANSITION_DELAY = "250ms";

@Directive({
    selector: "[hideOnscroll]",
})
export class HideOnscrollDirective implements AfterViewInit, OnDestroy {
    @Input("hideOnscroll") scrollArea;

    alive: boolean = true;
    scrollEndTimeout: any;

    constructor(private el: ElementRef,
                private renderer: Renderer2,
                private zone: NgZone,
                private domCtrl: DomController) {
    }

    ngAfterViewInit() {
        // After initializing all the elements , set their transition property
        this.init();

        this.zone.runOutsideAngular(() => {
            this.scrollArea.ionScrollStart.pipe(takeWhile(() => this.alive)).subscribe(ev => {
                this.domCtrl.read(() => {
                    if (this.scrollEndTimeout) {
                        clearTimeout(this.scrollEndTimeout);
                    }
                    this.hide();
                });
            });
            this.scrollArea.ionScrollEnd.pipe(takeWhile(() => this.alive)).subscribe(ev => {
                this.domCtrl.read(() => {
                    this.scrollEndTimeout = setTimeout(() => {
                        this.show();
                        this.scrollEndTimeout = null;
                    }, 500);
                });
            });
        });
    }

    ngOnDestroy() {
        this.alive = false;
    }

    show() {
        this.renderer.setStyle(this.el.nativeElement, "opacity", "1");
    }

    hide() {
        this.renderer.setStyle(this.el.nativeElement, "opacity", "0.2");
    }

    init() {
        this.renderer.setStyle(this.el.nativeElement, "transition", `opacity ${ TRANSITION_DELAY }`);
    }
}
