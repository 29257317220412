import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";
import { ILocationDto } from "../../gyzmo-commons/interfaces/IUserDto";
import { Location } from "../models/location.model";
import { AddressDto } from "./address.dto";
import { CompanyDto } from "./company.dto";

export class LocationDto extends BaseDto<Location> implements ILocationDto {
    public id: string = "";
    public wording: string = "";
    public mainAddress: AddressDto = null;
    public company: CompanyDto = null;

    constructor() {
        super();

        this.mainAddress = new AddressDto();
        this.company = new CompanyDto();
    }

    public static fromBody(body: any): LocationDto {
        let locationDto = new LocationDto();

        locationDto.id = "" + body.id;
        locationDto.wording = body.wording;

        if (body.mainAddress) {
            locationDto.mainAddress = AddressDto.fromBody(body.mainAddress);
        }

        if (body.company) {
            locationDto.company = CompanyDto.fromBody(body.company);
        }

        return locationDto;
    }

    public static fromModel(location: Location): LocationDto {
        let locationDto = new LocationDto();

        locationDto.id = location.id;
        locationDto.wording = location.wording;
        if (location.company) {
            locationDto.company = CompanyDto.fromModel(location.company);
        }
        if (location.mainAddress) {
            locationDto.mainAddress = AddressDto.fromModel(location.mainAddress);
        }

        return locationDto;
    }

    public toBody() {
        const clone: any = { ...this };

        clone.mainAddress = this.mainAddress.toBody();
        clone.company = this.company.toBody();

        return clone;
    }

    public toModel(): Location {
        let location = new Location();

        location.id = this.id;
        location.wording = this.wording;

        if (this.company) {
            location.company = this.company.toModel();
        }

        if (this.mainAddress) {
            location.mainAddress = this.mainAddress.toModel();
        }

        return location;
    }
}
