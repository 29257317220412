import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class AttachedDocument extends ModelBase {
    static readonly TABLENAME = "AttachedDocuments";

    id: string = "";
    wording: string = "";
    file: string = "";

    constructor() {
        super(AttachedDocument.TABLENAME);
    }
}
