import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
    selector: "app-typeahead",
    templateUrl: "typeahead-selection.modal.html",
    styleUrls: ["typeahead-selection.modal.scss"],
})
export class TypeaheadSelectionModal implements OnInit {
    @Input() items: { id: string, wording: string }[] = [];
    @Input() selectedItems: string[] = [];
    @Input() title = "Select...";

    @Output() selectionCancel = new EventEmitter<void>();
    @Output() selectionChange = new EventEmitter<string[]>();

    filteredItems: { id: string, wording: string }[] = [];
    workingSelectedValues: string[] = [];

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
        this.filteredItems = [...this.items];
        this.workingSelectedValues = [...this.selectedItems];
    }

    trackItems(index: number, item: { id: string, wording: string }) {
        return item.id;
    }

    searchbarInput(ev) {
        this.filterList(ev.target.value);
    }

    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    filterList(searchQuery: string | undefined) {
        /**
         * If no search query is defined,
         * return all options.
         */
        if (searchQuery === undefined) {
            this.filteredItems = [...this.items];
        } else {
            /**
             * Otherwise, normalize the search
             * query and check to see which items
             * contain the search query as a substring.
             */
            const normalizedQuery = searchQuery.toLowerCase();
            this.filteredItems = this.items.filter((item) => {
                return item.wording.toLowerCase().includes(normalizedQuery);
            });
        }
    }

    public dismiss() {
        void this.modalController.dismiss();
    }

    public onSelect(item: { id: string; wording: string }) {
        void this.modalController.dismiss({ item: item });
    }
}
