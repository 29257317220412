import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { environment } from "../../environments/environment";
import { KeyValueDbDao } from "../dao/db/keyValue.db.dao";
import { MOCK_VERSION_KEY } from "../dtos/mockVersion.dto";
import { AssetsHelper } from "../helpers/assets.helper";
import { LoggerService } from "../services/logs/logger.service";
import { AngularHttpClient } from "./angularHttpClient";
import { HttpResponse } from "./httpResponse";

@Injectable({
    providedIn: "root",
})
export class AngularHttpClientWithMock extends AngularHttpClient {
    constructor(logger: LoggerService,
                httpClient: HttpClient,
                protected assetsHelper: AssetsHelper,
                protected keyValueDbDao: KeyValueDbDao) {
        super(logger, httpClient);
    }

    public async delete(url: string, headers: Record<string, unknown>): Promise<HttpResponse> {
        let filename = await this.urlToFilename("DELETE", url);
        let mockUrl = await this.getMockUrl(filename);
        let mockResponse = await this.assetsHelper.readFileAsText(mockUrl)
            .catch(() => {
                return null;
            });

        if (mockResponse) {
            return this.deserializeResponse(mockResponse);
        } else if (!environment.production) {
            let response = await super.delete(url, headers)
                .catch(errorResponse => {
                    return errorResponse;
                });

            this.serializeResponse(response, filename);

            return response;
        }

        return new HttpResponse(200, null, new Map<string, string>());
    }

    public async get(url: string, headers: Record<string, unknown>): Promise<HttpResponse> {
        let filename = await this.urlToFilename("GET", url);
        let mockUrl = await this.getMockUrl(filename);
        let mockResponse = await this.assetsHelper.readFileAsText(mockUrl)
            .catch(() => {
                return null;
            });

        if (mockResponse) {
            return this.deserializeResponse(mockResponse);
        } else if (!environment.production) {
            let response = await super.get(url, headers)
                .catch(errorResponse => {
                    return errorResponse;
                });

            this.serializeResponse(response, filename);

            return response;
        }

        return new HttpResponse(200, null, new Map<string, string>());
    }

    public async post(url: string, headers: Record<string, unknown>, data: any): Promise<HttpResponse> {
        let filename = await this.urlToFilename("POST", url);
        let mockUrl = await this.getMockUrl(filename);
        let mockResponse = await this.assetsHelper.readFileAsText(mockUrl)
            .catch(() => {
                return null;
            });

        if (mockResponse) {
            return this.deserializeResponse(mockResponse);
        } else if (!environment.production) {
            let response = await super.post(url, headers, data)
                .catch(errorResponse => {
                    return errorResponse;
                });

            this.serializeResponse(response, filename);

            return response;
        }

        return new HttpResponse(200, null, new Map<string, string>());
    }

    public async put(url: string, headers: Record<string, unknown>, data: any): Promise<HttpResponse> {
        let filename = await this.urlToFilename("PUT", url);
        let mockUrl = await this.getMockUrl(filename);
        let mockResponse = await this.assetsHelper.readFileAsText(mockUrl)
            .catch(() => {
                return null;
            });

        if (mockResponse) {
            return this.deserializeResponse(mockResponse);
        } else if (!environment.production) {
            let response = await super.put(url, headers, data)
                .catch(errorResponse => {
                    return errorResponse;
                });

            this.serializeResponse(response, filename);

            return response;
        }

        return new HttpResponse(200, null, new Map<string, string>());
    }

    protected deserializeResponse(mockResponseContent) {
        const mockResponse = JSON.parse(mockResponseContent, this.reviver);

        if (mockResponse.status >= 200 && mockResponse.status < 300) {
            return new HttpResponse(mockResponse.status, mockResponse.body, mockResponse.headers);
        } else {
            throw new HttpErrorResponse({ status: mockResponse.status, headers: mockResponse.headers, statusText: mockResponse.body });
        }
    }

    protected async getMockUrl(filename: string) {
        let mockSubDir = "";
        let keyValue = await this.keyValueDbDao.get(MOCK_VERSION_KEY);

        if (keyValue) {
            mockSubDir = keyValue.value + "/";
        }

        return "mocked-responses/" + mockSubDir + filename;
    }

    protected reviver(key, value) {
        if (typeof value === "object" && value !== null) {
            if (value.dataType === "Map") {
                return new Map(value.value);
            }
        }
        return value;
    }

    protected async urlToFilename(method: string, url: string): Promise<string> {
        let kv = await this.keyValueDbDao.get("serverAddress");
        let serverUrl = environment.servers[0].url;
        if (kv != null) {
            serverUrl = environment.servers[kv.value].url;
        }

        if (!serverUrl.endsWith("/")) {
            serverUrl += "/";
        }

        let filename = url.replace(serverUrl, "");
        filename = method + "_" + filename.replace(/[/:=?.#&]/gi, "_") + ".json";
        filename = filename.replace(/_+/g, "_");

        return filename;
    }

    private replacer(key, value) {
        const originalObject = this[key];
        if (originalObject instanceof Map) {
            return {
                dataType: "Map",
                value: Array.from(originalObject.entries()), // or with spread: value: [...originalObject]
            };
        } else {
            return value;
        }
    }

    private serializeResponse(response: HttpResponse | HttpErrorResponse, filename: string) {
        let fileContent = JSON.stringify(response, this.replacer, 2);
        let blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
        saveAs(blob, filename);
    }
}
