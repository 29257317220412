import { Component, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as Hammer from "hammerjs";
import { DeviceHelper } from "../helpers/device.helper";
import { BasePage } from "./base.page.component";

@Component({ template: "" })
export abstract class HammerJsPage extends BasePage {
    @ViewChild("dragWrapper", { static: false }) dragWrapperElementRef: ElementRef;
    @ViewChild("drag", { static: false }) dragElementRef: ElementRef;

    hammerJsInitialized = false;

    originalSize = {
        width: 850,
        height: 500,
    };

    defaultOffset = {
        x: 0,
        y: 0,
    };

    current = {
        x: 0,
        y: 0,
        z: 1,
        zooming: false,
        width: this.originalSize.width * 1,
        height: this.originalSize.height * 1,
    };

    last = {
        x: this.current.x,
        y: this.current.y,
        z: this.current.z,
    };

    protected constructor(router: Router,
                          route: ActivatedRoute,
                          protected deviceHelper: DeviceHelper) {
        super(router, route);
    }

    get isHammerJsInitialized(): boolean {
        return this.hammerJsInitialized;
    }

    public resetHammerJs() {
        this.current.x = 0;
        this.current.y = 0;
        this.current.z = 0;

        this.last.x = this.current.x;
        this.last.y = this.current.y;
        this.last.z = this.current.z;

        this.setDefaultZoom();
    }

    public zoomIn() {
        this.current.z = this.current.z * 1.4;
        this.update();
    }

    public zoomOut() {
        this.current.z = this.current.z / 1.4;
        if (this.current.z < 0.1) {
            this.current.z = 0.1;
        }

        this.update();
    }

    public initializeHammerJs() {
        let hammertime = new Hammer(this.dragWrapperElementRef.nativeElement, {});

        hammertime.get("pinch").set({ enable: true });
        hammertime.get("pan").set({ threshold: 0 });

        let fixHammerjsDeltaIssue = undefined;
        let pinchStart = { x: undefined, y: undefined };
        let lastEvent = undefined;

        hammertime.on("pan", (e) => {
            if (lastEvent !== "pan") {
                fixHammerjsDeltaIssue = {
                    x: e.deltaX,
                    y: e.deltaY,
                };
            }

            this.current.x = this.last.x + e.deltaX - fixHammerjsDeltaIssue.x;
            this.current.y = this.last.y + e.deltaY - fixHammerjsDeltaIssue.y;
            lastEvent = "pan";
            this.update();
        });

        hammertime.on("pinch", (e) => {
            let d = this.scaleFrom(pinchZoomOrigin, this.last.z, this.last.z * e.scale);
            this.current.x = d.x + this.last.x;
            this.current.y = d.y + this.last.y;
            this.current.z = d.z + this.last.z;
            lastEvent = "pinch";
            this.update();
        });

        let pinchZoomOrigin = undefined;
        hammertime.on("pinchstart", (e) => {
            pinchStart.x = e.center.x;
            pinchStart.y = e.center.y;
            pinchZoomOrigin = this.getRelativePosition(this.dragElementRef.nativeElement, {
                x: pinchStart.x,
                y: pinchStart.y,
            }, this.originalSize, this.current.z);
            lastEvent = "pinchstart";
        });

        hammertime.on("panend", (e) => {
            this.last.x = this.current.x;
            this.last.y = this.current.y;
            lastEvent = "panend";
        });

        hammertime.on("pinchend", (e) => {
            this.last.x = this.current.x;
            this.last.y = this.current.y;
            this.last.z = this.current.z;
            lastEvent = "pinchend";
        });

        this.setDefaultZoom();

        if (!this.deviceHelper.isRunningOnDevice()) {
            this.dragElementRef.nativeElement.addEventListener("wheel", ($event) => {
                $event.preventDefault();

                if ($event.deltaY < 0) {
                    this.zoomIn();
                } else {
                    this.zoomOut();
                }
            });
        }

        this.hammerJsInitialized = true;
    }

    private getCoordinateShiftDueToScale(size, scale) {
        let newWidth = scale * size.width;
        let newHeight = scale * size.height;
        let dx = (newWidth - size.width) / 2;
        let dy = (newHeight - size.height) / 2;
        return {
            x: dx,
            y: dy,
        };
    }

    private getCoords(elem) { // crossbrowser version
        let box = elem.getBoundingClientRect();

        let body = document.body;
        let docEl = document.documentElement;

        let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        let clientTop = docEl.clientTop || body.clientTop || 0;
        let clientLeft = docEl.clientLeft || body.clientLeft || 0;

        let top = box.top + scrollTop - clientTop;
        let left = box.left + scrollLeft - clientLeft;

        return { x: Math.round(left), y: Math.round(top) };
    }

    private getRelativePosition(dragElement, point, originalSize, scale) {
        let domCoords = this.getCoords(dragElement);

        let elementX = point.x - domCoords.x;
        let elementY = point.y - domCoords.y;

        let relativeX = elementX / (originalSize.width * scale / 2) - 1;
        let relativeY = elementY / (originalSize.height * scale / 2) - 1;
        return { x: relativeX, y: relativeY };
    }

    private scaleFrom(zoomOrigin, currentScale, newScale) {
        let currentShift = this.getCoordinateShiftDueToScale(this.originalSize, currentScale);
        let newShift = this.getCoordinateShiftDueToScale(this.originalSize, newScale);

        let zoomDistance = newScale - currentScale;

        let shift = {
            x: currentShift.x - newShift.x,
            y: currentShift.y - newShift.y,
        };

        let output = {
            x: zoomOrigin.x * shift.x,
            y: zoomOrigin.y * shift.y,
            z: zoomDistance,
        };

        return output;
    }

    private setDefaultZoom() {
        //SetTimeout obligatoire sinon le texte des dommages ne sont plus bien positionnés
        setTimeout(() => {
            this.defaultOffset.x = (this.dragElementRef.nativeElement.parentElement.clientWidth - this.dragElementRef.nativeElement.clientWidth) / 2;
            this.defaultOffset.y = (this.dragElementRef.nativeElement.parentElement.clientHeight - this.dragElementRef.nativeElement.clientHeight) / 2;

            this.current.z = Math.min(
                (this.dragElementRef.nativeElement.parentElement.clientWidth / this.dragElementRef.nativeElement.clientWidth),
                (this.dragElementRef.nativeElement.parentElement.clientHeight / this.dragElementRef.nativeElement.clientHeight));
            this.last.z = this.current.z;

            this.update();
        }, 1);
    }

    private update() {
        this.current.height = this.originalSize.height * this.current.z;
        this.current.width = this.originalSize.width * this.current.z;
        this.dragElementRef.nativeElement.style.transform = "translate3d(" + (this.current.x + this.defaultOffset.x) + "px, "
                                                            + (this.current.y + this.defaultOffset.y) + "px, 0) "
                                                            + "scale(" + this.current.z + ")";
    }
}
