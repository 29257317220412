import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Movement } from "../models/movement.model";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";
import { EquipmentDto } from "./equipment.dto";
import { LocationDto } from "./location.dto";
import { ServerDto } from "./server.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class MovementDto extends BaseDto<Movement> {
    id: string = "";
    documentNumber: string = "";
    contractNumber: string = "";
    startDate: DateTime = null;
    returnDate: DateTime = null;
    returnFuel: number = 0;
    returnFuel2: number = 0;
    returnMileage: number = 0;
    returnMileage2: number = 0;
    startFuel: number = 0;
    startFuel2: number = 0;
    startMileage: number = 0;
    startMileage2: number = 0;
    customer: ThirdPartyDto = null;
    driver: ThirdPartyDto = null;
    startLocation: LocationDto;
    returnLocation: LocationDto;
    relatedTable: string = null;
    includingTaxAmount: string = "";

    // N'appartient pas au model, mais sert lors de la création d'un départ/retour sur tablette
    // N'est pas hydraté, c'est toujours un EquipmentBase
    equipment: EquipmentDto = null;

    // volatile
    public previousStartMileage: number = 0;
    public previousStartMileage2: number = 0;
    public previousReturnMileage: number = 0;
    public previousReturnMileage2: number = 0;
    public previousStartFuel: number = 0;
    public previousStartFuel2: number = 0;
    public previousReturnFuel: number = 0;
    public previousReturnFuel2: number = 0;

    constructor() {
        super();

        this.customer = new ThirdPartyDto();
        this.driver = new ThirdPartyDto();
        this.startLocation = new LocationDto();
        this.returnLocation = new LocationDto();
    }

    public static fromBody(body: any, serverDto: ServerDto): MovementDto {
        let movementDto = new MovementDto();

        movementDto.id = "" + body.id;
        movementDto.documentNumber = body.documentNumber;
        movementDto.contractNumber = body.contractNumber;

        movementDto.startDate = DateHelper.tryFromFormat(body.startDate, DATE_NODEJS_FORMAT);
        movementDto.returnDate = DateHelper.tryFromFormat(body.returnDate, DATE_NODEJS_FORMAT);
        movementDto.returnFuel = body.returnFuel;
        movementDto.returnFuel2 = body.returnFuel2;
        movementDto.returnMileage = body.returnMileage;
        movementDto.returnMileage2 = body.returnMileage2;
        movementDto.startFuel = body.startFuel;
        movementDto.startFuel2 = body.startFuel2;
        movementDto.startMileage = body.startMileage;
        movementDto.startMileage2 = body.startMileage2;

        movementDto.previousStartMileage = movementDto.startMileage;
        movementDto.previousStartMileage2 = movementDto.startMileage2;
        movementDto.previousReturnMileage = movementDto.returnMileage;
        movementDto.previousReturnMileage2 = movementDto.returnMileage2;
        movementDto.previousStartFuel = movementDto.startFuel;
        movementDto.previousStartFuel2 = movementDto.startFuel2;
        movementDto.previousReturnFuel = movementDto.returnFuel;
        movementDto.previousReturnFuel2 = movementDto.returnFuel2;

        if (body.relatedTables) {
            movementDto.relatedTable = body.relatedTables.wording;
        }

        if (body.customer) {
            movementDto.customer = ThirdPartyDto.fromBody(body.customer);
        }

        if (body.driver) {
            movementDto.driver = ThirdPartyDto.fromBody(body.driver);
        }

        if (body.equipment) {
            movementDto.equipment = EquipmentDto.fromBody(body.equipment);
        }

        if (body.startLocation) {
            movementDto.startLocation = LocationDto.fromBody(body.startLocation);
        }

        if (body.returnLocation) {
            movementDto.returnLocation = LocationDto.fromBody(body.returnLocation);
        }

        if (body.event) {
            movementDto.includingTaxAmount = body.event.includingTaxAmount;
        }

        return movementDto;
    }

    static fromModel(movement: Movement): MovementDto {
        let movementDto = new MovementDto();

        movementDto.id = movement.id;

        movementDto.documentNumber = movement.documentNumber;
        movementDto.contractNumber = movement.contractNumber;

        movementDto.startDate = DateTime.fromISO(movement.startDate);
        movementDto.returnDate = DateTime.fromISO(movement.returnDate);
        movementDto.returnFuel = movement.returnFuel;
        movementDto.returnFuel2 = movement.returnFuel2;
        movementDto.returnMileage = movement.returnMileage;
        movementDto.returnMileage2 = movement.returnMileage2;
        movementDto.startFuel = movement.startFuel;
        movementDto.startFuel2 = movement.startFuel2;
        movementDto.startMileage = movement.startMileage;
        movementDto.startMileage2 = movement.startMileage2;
        movementDto.relatedTable = movement.relatedTable;
        movementDto.includingTaxAmount = movement.includingTaxAmount;

        movementDto.previousStartMileage = movement.startMileage;
        movementDto.previousStartMileage2 = movement.startMileage2;
        movementDto.previousReturnMileage = movement.returnMileage;
        movementDto.previousReturnMileage2 = movement.returnMileage2;
        movementDto.previousStartFuel = movement.startFuel;
        movementDto.previousStartFuel2 = movement.startFuel2;
        movementDto.previousReturnFuel = movement.returnFuel;
        movementDto.previousReturnFuel2 = movement.returnFuel2;

        if (movement.customer) {
            movementDto.customer = ThirdPartyDto.fromModel(movement.customer);
        }
        if (movement.driver) {
            movementDto.driver = ThirdPartyDto.fromModel(movement.driver);
        }

        if (movement.startLocation) {
            movementDto.startLocation = LocationDto.fromModel(movement.startLocation);
        }
        if (movement.returnLocation) {
            movementDto.returnLocation = LocationDto.fromModel(movement.returnLocation);
        }

        return movementDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.startDate = DateHelper.tryToFormat(this.startDate, DATE_NODEJS_FORMAT);
        clone.returnDate = DateHelper.tryToFormat(this.returnDate, DATE_NODEJS_FORMAT);

        clone.customer = this.customer.toBody();
        clone.driver = this.driver.toBody();
        clone.startLocation = this.startLocation.toBody();
        clone.returnLocation = this.returnLocation.toBody();

        if (this.equipment) {
            clone.equipment = this.equipment.toBody();
        }

        delete clone.previousStartMileage;
        delete clone.previousStartMileage2;
        delete clone.previousReturnMileage;
        delete clone.previousReturnMileage2;
        delete clone.previousStartFuel;
        delete clone.previousStartFuel2;

        return clone;
    }

    toModel() {
        let movement = new Movement();

        movement.id = this.id;
        movement.documentNumber = this.documentNumber;
        movement.contractNumber = this.contractNumber;

        movement.startDate = DateHelper.tryToISO(this.startDate);
        movement.returnDate = DateHelper.tryToISO(this.returnDate);
        movement.returnFuel = this.returnFuel;
        movement.returnFuel2 = this.returnFuel2;
        movement.returnMileage = this.returnMileage;
        movement.returnMileage2 = this.returnMileage2;
        movement.startFuel = this.startFuel;
        movement.startFuel2 = this.startFuel2;
        movement.startMileage = this.startMileage;
        movement.startMileage2 = this.startMileage2;
        movement.relatedTable = this.relatedTable;
        movement.includingTaxAmount = this.includingTaxAmount;

        if (this.customer) {
            movement.customer = this.customer.toModel();
        }

        if (this.driver) {
            movement.driver = this.driver.toModel();
        }

        if (this.startLocation) {
            movement.startLocation = this.startLocation.toModel();
        }

        if (this.returnLocation) {
            movement.returnLocation = this.returnLocation.toModel();
        }

        return movement;
    }
}

