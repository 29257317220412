import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { CacheDbDao } from "../dao/db/cache.db.dao";
import { DateProvider } from "../interfaces/dateProvider";
import { CachedValue } from "../models/cachedValue.model";
import { LoggerService } from "./logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class CacheService {
    constructor(private logger: LoggerService,
                private cacheDbDao: CacheDbDao,
                private dateProvider: DateProvider) {
    }

    public isCached(key: string): Promise<boolean> {
        return this.cacheDbDao.isCached(key);
    }

    public getCached(key: string): Promise<CachedValue> {
        return this.cacheDbDao.get(key, false)
            .then(value => {
                return value;
            });
    }

    public cache(key: string, value: string, expirationDate: DateTime): Promise<CachedValue> {
        let cachedValue = new CachedValue(key, value, this.dateProvider.now(), expirationDate);

        return this.cacheDbDao.save(cachedValue);
    }

    public cleanCache(force: boolean = false): Promise<void> {
        if (force) {
            return this.cacheDbDao.deleteAll()
                .then(() => {
                    return;
                }).catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                });
        } else {
            return this.cacheDbDao.cleanCache()
                .then(() => {
                    return;
                }).catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                });
        }
    }

    public getCachedKeys(): Promise<string[]> {
        return this.cacheDbDao.getCachedKeys();
    }
}
