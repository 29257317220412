import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../../../../gyzmo-commons/helpers/null.helper";
import { DateProvider } from "../../../../gyzmo-commons/interfaces/dateProvider";
import { CacheService } from "../../../../gyzmo-commons/services/cache.service";
import { LoggerService } from "../../../../gyzmo-commons/services/logs/logger.service";
import { InspectionFacesWsDaoV2 } from "../../../dao/ws/inspection/v2/face.ws.dao";
import { ServersConnectionsProvider } from "../../../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class InspectionFacesServiceV2 {
    constructor(private logger: LoggerService,
                private inspectionFacesWsDao: InspectionFacesWsDaoV2,
                private cacheService: CacheService,
                private dateProvider: DateProvider,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async synchronizeForModel(model: string) {
        let isCached = await this.cacheService.getCached("model/" + model);
        if (!isCached || isCached.value != "1") {
            let modelFaces = await this.inspectionFacesWsDao.getForModel(this.serversConnectionsProvider.getServerConnection(), model);
            for (const face of modelFaces) {
                this.logger.info(this.constructor.name, "Caching face picture " + face.faceId);
                await this.cacheService.cache("face/" + face.faceId, face.facePicture, this.dateProvider.now().plus({ years: 10 }));
            }
            await this.cacheService.cache("model/" + model, "1", this.dateProvider.now().plus({ years: 10 }));
        }
    }

    public async getFacePicture(faceId: string) {
        let cache = await this.cacheService.getCached("face/" + faceId);
        if (!cache || isNullOrEmpty(cache.value)) {
            this.logger.error(this.constructor.name, "Face " + faceId + " is not cached as it should be !");
            return null;
        }

        return cache.value;
    }
}
