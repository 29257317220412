import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../helpers/pipes/pipes.module";
import { TimePickerPopoverComponent } from "./time-picker-popover.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
        TranslateModule,
    ],
    declarations: [
        TimePickerPopoverComponent,
    ],
    exports: [
        TimePickerPopoverComponent,
    ],
})
export class TimePickerPopoverModule {
}
