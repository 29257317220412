import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";

export class GroupCategory extends ModelBase {
    static readonly TABLENAME = "GroupCategories";

    public id: string = "";
    public wording: string = "";
    public order: string = "";

    constructor() {
        super(GroupCategory.TABLENAME);
    }
}
