import { Face } from "../../../models/inspection/v2/face.model";
import { BaseDto } from "../../../../gyzmo-commons/dtos/base/base.dto";
import { ZoneDto } from "./zone.dto";

export class FaceDto extends BaseDto<Face> {
    public id: string = "";
    public wording: string = "";
    public order: string = "";

    public zones: ZoneDto[] = [];

    // volatile
    public picture: string = "";

    static fromBody(body: any, inspectionId: string): FaceDto {
        let faceDto = new FaceDto();

        faceDto.id = "" + body.id;
        faceDto.wording = body.wording;

        if (body.order) {
            faceDto.order = body.order;
        }

        if (body.zones) {
            body.zones.forEach(zone => {
                faceDto.zones.push(ZoneDto.fromBody(zone, inspectionId, faceDto.id));
            });
        }

        return faceDto;
    }

    static fromModel(face: Face) {
        let faceDto = new FaceDto();

        faceDto.id = face.id;
        faceDto.wording = face.wording;
        faceDto.order = face.order;

        if (face.zones) {
            face.zones.forEach(zone => {
                faceDto.zones.push(ZoneDto.fromModel(zone));
            });
        }

        return faceDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.zones = [];
        this.zones.forEach(zone => {
            clone.zones.push(zone.toBody());
        });

        delete clone.picture;

        return clone;
    }

    public toModel(): Face {
        let face = new Face();

        face.id = this.id;
        face.wording = this.wording;
        face.order = this.order;

        if (this.zones) {
            this.zones.forEach(zone => {
                face.zones.push(zone.toModel());
            });
        }

        return face;
    }
}
