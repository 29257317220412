import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../helpers/pipes/pipes.module";
import { IriumGalleryDocument } from "./irium-gallery-document";

@NgModule(
    {
        declarations: [
            IriumGalleryDocument,
        ],
        imports: [
            CommonModule,
            IonicModule,
            TranslateModule,
            PipesModule,
            FormsModule,
        ],
        exports: [
            IriumGalleryDocument,
        ],
    })

export class IriumGalleryDocumentModule {
}
