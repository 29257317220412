// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-row {
  height: 100%;
}

.container {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.backgroundCanvas {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

.signaturePad {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

ion-fab {
  position: relative;
}

.secondary-toolbar div {
  display: flex;
  flex-wrap: wrap;
}
.secondary-toolbar .selected {
  border-bottom: 4px solid var(--ion-color-primary);
  padding-bottom: 0;
}
.secondary-toolbar .separator {
  margin: 10px 5px;
  padding: 0;
  border-left: thin solid var(--ion-color-medium-light);
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/modals/draw/draw.modal.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGI;EACI,aAAA;EACA,eAAA;AAAR;AAGI;EACI,iDAAA;EACA,iBAAA;AADR;AAII;EACI,gBAAA;EACA,UAAA;EACA,qDAAA;AAFR","sourcesContent":[".container-row {\r\n    height: 100%;\r\n}\r\n\r\n.container {\r\n    display: inline-block;\r\n    position: relative;\r\n    overflow: hidden;\r\n    margin: auto;\r\n}\r\n\r\n.backgroundCanvas {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    max-width: 100%;\r\n    max-height: 100%;\r\n}\r\n\r\n.signaturePad {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    max-width: 100%;\r\n    max-height: 100%;\r\n}\r\n\r\nion-fab {\r\n    position: relative;\r\n}\r\n\r\n.secondary-toolbar {\r\n    div {\r\n        display: flex;\r\n        flex-wrap: wrap;\r\n    }\r\n\r\n    .selected {\r\n        border-bottom: 4px solid var(--ion-color-primary);\r\n        padding-bottom: 0;\r\n    }\r\n\r\n    .separator {\r\n        margin: 10px 5px;\r\n        padding: 0;\r\n        border-left: thin solid var(--ion-color-medium-light);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
