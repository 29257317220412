import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { DATE_FORMAT, DATE_NODEJS_FORMAT } from "../../interfaces/constants";

@Pipe({ name: "backEndDate" })
export class BackEndDatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(date: string | Date | DateTime) {
        if (date instanceof DateTime) {
            return date.toFormat(DATE_FORMAT);
        } else if (date instanceof Date) {
            return DateTime.fromJSDate(date).toFormat(DATE_FORMAT);
        } else if (typeof date === "string") {
            return DateTime.fromFormat(date, DATE_NODEJS_FORMAT).toFormat(DATE_FORMAT);
        } else if (date == null) {
            return this.translateService.instant("IONIC_No_date");
        } else {
            return this.translateService.instant("IONIC_Unknown_date_format");
        }
    }
}
