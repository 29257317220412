import { ModelBase } from "./base/model.base";

export class IdWording extends ModelBase {
    static readonly TABLENAME = "IdWordings";

    public id: string = "";
    public wording: string = "";

    constructor() {
        super(IdWording.TABLENAME);
    }

    public static fromRow(row: any): IdWording {
        let model = new IdWording();
        model.id = row.id;
        model.wording = row.wording;

        return model;
    }
}
