import { Component, forwardRef, Input } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { ActionSheetController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { LoadingHelper } from "../../helpers/loading.helper";
import { PictureHelper } from "../../helpers/picture.helper";
import { AttachmentKinds } from "../../interfaces/attachmentKinds";
import { PICTURE_QUALITY, PICTURE_RESOLUTION } from "../../interfaces/constants";
import { DateProvider } from "../../interfaces/dateProvider";
import { IAttachmentDto } from "../../interfaces/IAttachmentDto";
import { CameraService } from "../../services/camera.service";
import { FileService } from "../../services/file.service";
import { LoggerService } from "../../services/logs/logger.service";
import { AttachmentFactory } from "../../interfaces/attachmentFactory";

@Component({
    selector: "irium-gallery",
    templateUrl: "irium-gallery.html",
    styleUrls: ["irium-gallery.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => IriumGallery),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => IriumGallery),
        },
    ],
})
export class IriumGallery implements ControlValueAccessor, Validator {
    @Input() disabled: boolean = false;
    @Input() canEdit: boolean = false;
    @Input() canDelete: boolean = false;
    @Input() canAdd: boolean = false;
    @Input() showDocuments: boolean = false;

    @Input() object: string = "";
    @Input() key: string = "";

    value: IAttachmentDto[];
    touched = false;
    selectionIndex = -1;

    constructor(private logger: LoggerService,
                private loadingHelper: LoadingHelper,
                private dateProvider: DateProvider,
                private pictureHelper: PictureHelper,
                private cameraService: CameraService,
                private fileService: FileService,
                private translateService: TranslateService,
                private attachmentFactory: AttachmentFactory,
                private actionSheetCtrl: ActionSheetController) {
    }

    public showDocument(attachment: IAttachmentDto) {
        if (attachment.attachmentKind.id == AttachmentKinds.PHOTO) {
            void this.pictureHelper.viewImage(attachment.file, false);
        } else {
            void this.fileService.openDocument(attachment.file);
        }
    }

    public editPicture(attachment: IAttachmentDto) {
        void this.pictureHelper.viewImage(attachment.file, true)
            .then((result: string) => {
                if (result) {
                    attachment.file = result;

                    this.markAsTouched();
                    this.onChange(this.value);
                }
            });
    }

    public deleteDocument(attachment: IAttachmentDto) {
        if (!this.value || this.value.length <= 0) {
            return;
        }

        this.value = this.value.filter((object: IAttachmentDto) => {
            return object != attachment;
        });

        this.markAsTouched();
        this.onChange(this.value);
    }

    public getAttachments(): IAttachmentDto[] {
        if (!this.showDocuments) {
            return this.value.filter(value => {
                return value.attachmentKind.id == AttachmentKinds.PHOTO;
            });
        } else {
            return this.value;
        }
    }

    onChange = (value) => {
    };

    onTouched = () => {
    };

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any) {
        this.value = value;
    }

    markAsTouched() {
        this.onTouched();
        this.touched = true;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }

    public selectDocument($event, index: number) {
        $event.stopPropagation();

        if (this.selectionIndex != index) {
            this.selectionIndex = index;
        } else {
            this.selectionIndex = -1;
        }
    }

    public async selectDocumentSource() {
        let buttons = [
            {
                icon: "camera-outline",
                text: this.translateService.instant("IONIC_Camera"),
                data: {
                    action: "camera",
                },
                handler: () => {
                    this.addPicture();
                },
            },
            {
                icon: "image-outline",
                text: this.translateService.instant("IONIC_Gallery"),
                data: {
                    action: "gallery",
                },
                handler: () => {
                    this.addPictureFromGallery();
                },
            }];

        if (this.showDocuments) {
            buttons.push({
                icon: "document-outline",
                text: this.translateService.instant("IONIC_Document"),
                data: {
                    action: "document",
                },
                handler: () => {
                    this.addDocument();
                },
            });
        }

        const actionSheet = await this.actionSheetCtrl.create({
            header: this.translateService.instant("IONIC_Select_source"),
            buttons: buttons,
        });
        await actionSheet.present();
    }

    public deselectDocument($event) {
        this.selectionIndex = -1;
    }

    private addDocument() {
        this.fileService.pickAndReadDocument()
            .then(async (content) => {
                // Si on est dans le browser et qu'on fait un "back" sur la prise de photo, data64 undefined
                if (content) {
                    await this.loadingHelper.showLoading();
                    let attachment: IAttachmentDto = this.attachmentFactory.createAttachment();
                    attachment.modified = true;
                    attachment.object = this.object;
                    attachment.key = this.key;
                    attachment.date = this.dateProvider.now();
                    attachment.attachmentKind.id = AttachmentKinds.FICHIERS;
                    attachment.file = content;
                    this.value.push(attachment);
                    await this.loadingHelper.hideLoading();
                }
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
            });
    }

    private addPicture() {
        this.cameraService.getPicture(null, null, PICTURE_RESOLUTION, 85)
            .then(async (imageData) => {
                if (imageData) {
                    await this.loadingHelper.showLoading();
                    let attachment: IAttachmentDto = this.attachmentFactory.createAttachment();
                    attachment.modified = true;
                    attachment.object = this.object;
                    attachment.key = this.key;
                    attachment.date = this.dateProvider.now();
                    attachment.attachmentKind.id = AttachmentKinds.PHOTO;
                    attachment.file = await this.pictureHelper.watermarkPicture(imageData, PICTURE_QUALITY);
                    this.value.push(attachment);
                    await this.loadingHelper.hideLoading();

                    this.markAsTouched();
                    this.onChange(this.value);
                }
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
            });
    }

    private addPictureFromGallery() {
        this.cameraService.getPictureFromGallery()
            .then(async (imageData) => {
                // Si on est dans le browser et qu'on fait un "back" sur la prise de photo, data64 undefined
                if (imageData) {
                    await this.loadingHelper.showLoading();
                    let attachment: IAttachmentDto = this.attachmentFactory.createAttachment();
                    attachment.modified = true;
                    attachment.object = this.object;
                    attachment.key = this.key;
                    attachment.date = this.dateProvider.now();
                    attachment.attachmentKind.id = AttachmentKinds.PHOTO;
                    attachment.file = await this.pictureHelper.watermarkPicture(imageData, PICTURE_QUALITY);
                    this.value.push(attachment);
                    await this.loadingHelper.hideLoading();
                }
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
            });
    }
}
