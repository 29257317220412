import { DamageValue } from "../../../models/inspection/common/damageValue.model";
import { BaseDto } from "../../../../gyzmo-commons/dtos/base/base.dto";

export class DamageValueDto extends BaseDto<DamageValue> {
    public id: string = "";
    public categoryId: string = "";
    public wording: string = "";
    public order: string = "";

    static fromBody(body: any, categoryId: string): DamageValueDto {
        let damageDto = new DamageValueDto();

        damageDto.id = "" + body.id;
        damageDto.categoryId = categoryId;
        damageDto.wording = body.wording;
        damageDto.order = body.order;

        return damageDto;
    }

    static fromModel(damageValue: DamageValue) {
        let damageValueDto = new DamageValueDto();

        damageValueDto.id = damageValue.id;
        damageValueDto.categoryId = damageValue.categoryId;
        damageValueDto.wording = damageValue.wording;
        damageValueDto.order = damageValue.order;

        return damageValueDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): DamageValue {
        let damageValue = new DamageValue();

        damageValue.id = this.id;
        damageValue.categoryId = this.categoryId;
        damageValue.wording = this.wording;
        damageValue.order = this.order;

        return damageValue;
    }
}
