import { VersionHelper } from "../helpers/version.helper";
import { Version } from "../models/version.model";
import { BaseDto } from "./base/base.dto";

export class VersionDto extends BaseDto<Version> {
    public id: number = 0;
    public version: string = "";

    constructor(id: number, version: string) {
        super();

        this.id = id;
        this.version = version;
    }

    public static fromModel(value: Version) {
        return new VersionDto(value.id, value.version);
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): Version {
        let version = new Version();

        version.id = this.id;
        version.version = this.version;

        return version;
    }

    public toDigits(): number[] {
        return VersionHelper.parseVersion(this.version);
    }
}
