import { Injectable } from "@angular/core";
import { EquipmentWsDao } from "../dao/ws/equipment.ws.dao";
import { WearWsDao } from "../dao/ws/inspection/v2/wear.ws.dao";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class EquipmentService {
    constructor(private equipmentWsDao: EquipmentWsDao,
                private wearWsDao: WearWsDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async getById(equipmentId: string) {
        let equipmentDto = await this.equipmentWsDao.getById(this.serversConnectionsProvider.getServerConnection(), equipmentId);
        equipmentDto.wear = await this.wearWsDao.getById(this.serversConnectionsProvider.getServerConnection(), equipmentId);

        return equipmentDto;
    }

    public getList(search?: string) {
        return this.equipmentWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection(), search);
    }
}
