// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  pointer-events: none;
  display: block;
}

ion-button {
  pointer-events: auto;
  overflow: hidden;
  position: relative;
  height: 50px;
  font-size: 1.1rem;
  min-width: 170px;
  margin: 0;
  --padding-start: 0;
  --padding-end: 0;
}
ion-button[disabled] {
  --ripple-color: transparent;
}
ion-button ion-row {
  width: 100%;
  height: 100%;
}
ion-button span {
  width: 100%;
  margin: 5px;
}
ion-button ion-icon {
  height: 100%;
  right: 0;
  top: 0;
  padding-right: 20px;
  padding-left: 20px;
  background: rgba(0, 0, 0, 0.14);
  font-size: 1.6rem;
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/components/gyzmo-button/gyzmo-button.component.scss"],"names":[],"mappings":"AACA;EACI,oBAAA;EACA,cAAA;AAAJ;;AAGA;EACI,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,SAAA;EAEA,kBAAA;EACA,gBAAA;AADJ;AAGI;EACI,2BAAA;AADR;AAII;EACI,WAAA;EACA,YAAA;AAFR;AAKI;EACI,WAAA;EACA,WAAA;AAHR;AAMI;EACI,YAAA;EACA,QAAA;EACA,MAAA;EACA,mBAAA;EACA,kBAAA;EACA,+BAAA;EACA,iBAAA;AAJR","sourcesContent":["// This prevents host to get a direct click\r\n:host {\r\n    pointer-events: none;\r\n    display: block;\r\n}\r\n\r\nion-button {\r\n    pointer-events: auto;\r\n    overflow: hidden;\r\n    position: relative;\r\n    height: 50px;\r\n    font-size: 1.1rem;\r\n    min-width: 170px;\r\n    margin: 0;\r\n\r\n    --padding-start: 0;\r\n    --padding-end: 0;\r\n\r\n    &[disabled] {\r\n        --ripple-color: transparent;\r\n    }\r\n\r\n    ion-row {\r\n        width: 100%;\r\n        height: 100%;\r\n    }\r\n\r\n    span {\r\n        width: 100%;\r\n        margin: 5px;\r\n    }\r\n\r\n    ion-icon {\r\n        height: 100%;\r\n        right: 0;\r\n        top: 0;\r\n        padding-right: 20px;\r\n        padding-left: 20px;\r\n        background: rgba(0, 0, 0, 0.14);\r\n        font-size: 1.6rem;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
