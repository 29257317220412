import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IonContent, ViewWillEnter, ViewWillLeave } from "@ionic/angular";
import { FormControlsHelper } from "../helpers/formControls.helper";
import { LoggerService } from "../services/logs/logger.service";
import { BasePage } from "./base.page.component";

@Component({
    template: "",
})
export abstract class FormValidationBasePage extends BasePage implements ViewWillEnter, ViewWillLeave {
    @ViewChild(IonContent, { read: ElementRef }) viewport: ElementRef;
    previousErrors = "";
    interval: any;

    protected constructor(router: Router,
                          route: ActivatedRoute,
                          protected logger: LoggerService) {
        super(router, route);
    }

    private $formGroup: FormGroup = null;

    public getFormGroup(): FormGroup {
        return this.$formGroup;
    }

    public async ionViewWillEnter() {
        this.$formGroup = await this.createFormGroup();

        this.interval = setInterval(() => {
            let formErrors = FormControlsHelper.getFormErrors(this.$formGroup);
            let errors = "";
            if (formErrors) {
                errors = JSON.stringify(formErrors, null, "  ");
            } else {
                errors = "No errors";
            }

            if (errors != this.previousErrors) {
                this.previousErrors = errors;
                this.logger.warn(this.constructor.name, "Form controls errors : " + errors);
            }
        }, 1000);
    }

    ionViewWillLeave(): void {
        clearInterval(this.interval);
    }

    public isFormValid() {
        if (this.$formGroup.invalid) {
            this.$formGroup.markAllAsTouched();
            void this.scrollToFirstError();

            return false;
        }

        return true;
    }

    public isFieldInvalid(field: string) {
        return !this.$formGroup.get(field).valid && this.$formGroup.get(field).touched;
    }

    public isGroupInvalid() {
        return !this.$formGroup.valid && this.$formGroup.touched;
    }

    public fieldHasError(field: string, error: string) {
        return this.$formGroup.get(field).hasError(error) && this.$formGroup.get(field).touched;
    }

    public groupHasError(error: string) {
        return this.$formGroup.hasError(error) && this.$formGroup.touched;
    }

    public getFormValue(field: string): string {
        if (this.$formGroup.get(field).value != null) {
            return "" + this.$formGroup.get(field).value;
        }

        return null;
    }

    public getFormControl(field: string) {
        return this.$formGroup.get(field);
    }

    protected async scrollToFirstError() {
        let elements = document.querySelectorAll(".ng-invalid");
        let firstElement = elements[0];
        if (firstElement.nodeName.toLowerCase() == "form") {
            firstElement = elements[1];
        }

        // Crawl parents until finding ion-content
        let yOffset = 0;
        let currentElement = firstElement;
        while (currentElement && currentElement.nodeName.toLowerCase() != "ion-content") {
            yOffset += currentElement["offsetTop"];
            currentElement = currentElement["offsetParent"];
        }

        await this.viewport.nativeElement.scrollToPoint(0, yOffset - 50, 100);
    }

    protected updateFormGroup(formGroup: FormGroup) {
        this.$formGroup = formGroup;
    }

    protected abstract createFormGroup(): Promise<FormGroup>;
}
