import { NgModule } from "@angular/core";
import { DatePickerPopoverModule } from "../../gyzmo-commons/components/date-picker/date-picker-popover/date-picker-popover.module";
import { TimePickerPopoverModule } from "../../gyzmo-commons/components/time-picker/time-picker-popover/time-picker-popover.module";
import { ProgressDialogPopoverModule } from "../../gyzmo-commons/popovers/progress-dialog/progress-dialog.module";
import { UserMenuPopoverModule } from "../../gyzmo-commons/popovers/user-menu/user-menu.module";
import { MaintenanceControlHelpModule } from "../components/maintenance-control/maintenance-control-help/maintenance-control-help.module";

@NgModule({
    imports: [
        ProgressDialogPopoverModule,
        DatePickerPopoverModule,
        TimePickerPopoverModule,
        UserMenuPopoverModule,
        MaintenanceControlHelpModule,
    ],
    declarations: [],
    providers: [],
})
export class PopoversModule {
}
