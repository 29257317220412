import { Injectable } from "@angular/core";
import { IServerConnection } from "../interfaces/IServerConnection";

@Injectable({
    providedIn: "root",
})
export class PingWs {
    static readonly WS = "technical/supervision/ping";

    public ping(serverConnection: IServerConnection): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();
            serverConnection.get(this.constructor.name, PingWs.WS, tokens, false, false, true)
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
