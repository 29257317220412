import { Injectable } from "@angular/core";
import { FaceDto } from "../../../../dto/inspection/v2/face.dto";
import { ServerConnection } from "../../../../http/serverConnection";

@Injectable({
    providedIn: "root",
})
export class InspectionFacesWsDaoV2 {
    static readonly WS = "inspection-faces";

    public getList(serverConnection: ServerConnection): Promise<FaceDto[]> {
        return new Promise<FaceDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, InspectionFacesWsDaoV2.WS + "?active=true&_limit=NOLIMIT", tokens)
                .then(response => {
                    let faceDtos: FaceDto[] = [];

                    if (response.body instanceof Array) {
                        response.body.forEach(value => {
                            let faceDto = FaceDto.fromBody(value, "");
                            faceDtos.push(faceDto);
                        });
                    }

                    resolve(faceDtos);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getForModel(serverConnection: ServerConnection, model: string): Promise<{ faceId: string, facePicture: string }[]> {
        return new Promise<{ faceId: string, facePicture: string }[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", model);

            serverConnection.get(this.constructor.name, InspectionFacesWsDaoV2.WS + "/parameters/:id?_limit=NOLIMIT", tokens)
                .then(response => {
                    let result: { faceId: string, facePicture: string }[] = [];

                    if (response.body instanceof Array) {
                        response.body.forEach(value => {
                            let faceId = value.id;
                            let facePicture = value.attachments[0].file;
                            if (faceId && facePicture) {
                                result.push({ faceId, facePicture });
                            }
                        });
                    }

                    resolve(result);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
