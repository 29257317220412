import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";
import { Attachment } from "../../attachment.model";

export class ChecklistElement extends ModelBase {
    static readonly TABLENAME = "ChecklistElements_V2";

    public id: string = "";
    public inspectionId: string = "";
    public checklistId: string = "";
    public wording: string = "";
    public defaultValue: string = "";
    public savedValue: string = "";
    public checklistModelId: string = "";
    public technicalLink: string = "";
    public param: string = "";

    public attachments: Attachment[] = [];

    constructor() {
        super(ChecklistElement.TABLENAME);
    }
}
