import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { DriverInfo } from "../../models/driverInfo.model";

@Injectable({
    providedIn: "root",
})
export class DriverInfoDbDao extends DbDaoBase<DriverInfo> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + DriverInfo.TABLENAME + "_id"
                    + " ON " + DriverInfo.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + DriverInfo.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "birthDate DATE, "
                    + "driverLicenseNumber TEXT, "
                    + "driverLicenseIssuingDate DATE, "
                    + "driverLicenseIssuingPlace TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + DriverInfo.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + DriverInfo.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<DriverInfo> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + DriverInfo.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return DriverInfo.TABLENAME;
    }

    protected rowToModel(row: any): DriverInfo {
        let driverInfo = new DriverInfo();
        driverInfo.id = row.id;
        driverInfo.birthDate = row.birthDate;
        driverInfo.driverLicenseNumber = row.driverLicenseNumber;
        driverInfo.driverLicenseIssuingDate = row.driverLicenseIssuingDate;
        driverInfo.driverLicenseIssuingPlace = row.driverLicenseIssuingPlace;

        return driverInfo;
    }

    public save(driverInfo: DriverInfo): Promise<DriverInfo> {
        let query = "INSERT OR REPLACE INTO " + DriverInfo.TABLENAME + " (id, birthDate, driverLicenseNumber, driverLicenseIssuingDate, driverLicenseIssuingPlace) VALUES ("
                    + this.getValue(driverInfo.id)
                    + this.getValue(driverInfo.birthDate)
                    + this.getValue(driverInfo.driverLicenseNumber)
                    + this.getValue(driverInfo.driverLicenseIssuingDate)
                    + this.getValue(driverInfo.driverLicenseIssuingPlace, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return driverInfo;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
