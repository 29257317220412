import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { XVEGAID } from "../../../gyzmo-commons/http/header.constant";
import { ContactDetailDto } from "../../dto/contactDetail.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class ContactDetailsWsDao extends WsDao<ContactDetailDto> {
    static readonly WS = "third-parties/:thirdPartyId/contact-details";

    public getById(serverConnection: ServerConnection, id: string): Promise<ContactDetailDto> {
        throw new Error("Not implemented");
    }

    public save(serverConnection: ServerConnection, contactDetailDto: ContactDetailDto): Promise<ContactDetailDto> {
        return new Promise<ContactDetailDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            if (!isNullOrEmpty(contactDetailDto.id)) {
                tokens.set("thirdPartyId", contactDetailDto.thirdPartyId);
                tokens.set("id", contactDetailDto.id);

                serverConnection.put(this.constructor.name, ContactDetailsWsDao.WS + "/:id", tokens, contactDetailDto.toBody())
                    .then(response => {
                        resolve(contactDetailDto);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            } else {
                delete contactDetailDto.id;

                serverConnection.post(this.constructor.name, ContactDetailsWsDao.WS, tokens, contactDetailDto.toBody())
                    .then(response => {
                        contactDetailDto.id = response.headers.get(XVEGAID.toLowerCase());
                        resolve(contactDetailDto);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            }
        });
    }

    public getListByThirdPartyId(serverConnection: ServerConnection, thirdPartyId: string): Promise<ContactDetailDto[]> {
        return new Promise<ContactDetailDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("thirdPartyId", thirdPartyId);

            serverConnection.get(this.constructor.name, ContactDetailsWsDao.WS, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let contactDetail = ContactDetailDto.fromBody(value, thirdPartyId);
                            result.push(contactDetail);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
