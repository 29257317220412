import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class OfflineModeRequest extends ModelBase {
    static readonly TABLENAME = "OfflineModeRequests";

    public rowid = 0;
    public api = "";
    public date: string = "";
    public mode: string = "";
    public url: string = "";
    public headers = {};
    public data = {};
    public retryCount = 0;

    constructor() {
        super(OfflineModeRequest.TABLENAME);
    }
}
