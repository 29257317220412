import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../helpers/pipes/pipes.module";
import { BrowserCameraModal } from "./browserCamera.modal";

@NgModule(
    {
        declarations: [
            BrowserCameraModal,
        ],
        exports: [
            BrowserCameraModal,
        ], imports: [CommonModule,
                     IonicModule,
                     TranslateModule,
                     PipesModule], providers: [provideHttpClient(withInterceptorsFromDi())],
    })

export class BrowserCameraModalModule {
}
