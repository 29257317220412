import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";
import { DamageValue } from "./damageValue.model";

export class DamageCategory extends ModelBase {
    static readonly TABLENAME = "DamageCategories";

    public id: string = "";
    public wording: string = "";

    // volatile
    public possibleValues: DamageValue[] = [];

    constructor() {
        super(DamageCategory.TABLENAME);
    }
}
