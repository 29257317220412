import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Location } from "./location.model";
import { ThirdParty } from "./thirdParty.model";

export class Movement extends ModelBase {
    static readonly TABLENAME = "Movements";

    id: string = "";
    documentNumber: string = "";
    contractNumber: string = "";
    startDate: string = "";
    returnDate: string = "";
    returnFuel: number = 0;
    returnFuel2: number = 0;
    returnMileage: number = NaN;
    returnMileage2: number = NaN;
    startFuel: number = 0;
    startFuel2: number = 0;
    startMileage: number = NaN;
    startMileage2: number = NaN;
    customer: ThirdParty = null;
    driver: ThirdParty = null;
    startLocation: Location = null;
    returnLocation: Location = null;
    relatedTable: string = null;
    includingTaxAmount: string = "";

    constructor() {
        super(Movement.TABLENAME);

        this.customer = new ThirdParty();
        this.driver = new ThirdParty();
        this.startLocation = new Location();
        this.returnLocation = new Location();
    }
}
