import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { GyzmoButtonModule } from "../../../../../gyzmo-commons/components/gyzmo-button/gyzmo-button.module";
import { IriumGalleryModule } from "../../../../../gyzmo-commons/components/irium-gallery/irium-gallery.module";
import { IriumSelectModule } from "../../../../../gyzmo-commons/components/irium-select/irium-select.module";
import { IriumTitleModule } from "../../../../../gyzmo-commons/components/irium-title/irium-title.module";
import { CreateDamageModal } from "./create-damage.modal";

@NgModule(
    {
        declarations: [
            CreateDamageModal,
        ],
        imports: [
            CommonModule,
            FormsModule,
            IonicModule,
            TranslateModule,
            GyzmoButtonModule,
            IriumTitleModule,
            ReactiveFormsModule,
            IriumSelectModule,
            IriumGalleryModule,
        ],
        providers: [],
    })

export class CreateDamageModalModuleV2 {
}
