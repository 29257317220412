import { Injectable } from "@angular/core";
import { KeyValue } from "../../models/keyValue.model";
import { AppSqlProvider } from "../../persistence/app.sql.provider";
import { LoggerService } from "../../services/logs/logger.service";
import { DbDaoBase } from "./base/db.dao.base";

@Injectable({
    providedIn: "root",
})
export class KeyValueDbDao extends DbDaoBase<KeyValue> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + KeyValue.TABLENAME + "_key"
                    + " ON " + KeyValue.TABLENAME + "(key);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + KeyValue.TABLENAME
                    + " ("
                    + "key TEXT PRIMARY KEY,"
                    + "value TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(key: string): Promise<any> {
        let selectQuery = "DELETE FROM " + KeyValue.TABLENAME + " WHERE key='" + key + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + KeyValue.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(key: string, hydrate: boolean = false): Promise<KeyValue> {
        let selectQuery = "SELECT * FROM " + KeyValue.TABLENAME + " WHERE key = '" + key + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return KeyValue.TABLENAME;
    }

    protected rowToModel(row: any): KeyValue {
        return new KeyValue(row.key, row.value);
    }

    public save(keyValue: KeyValue): Promise<KeyValue> {
        let query = "INSERT OR REPLACE INTO " + KeyValue.TABLENAME + " (key, value) VALUES ("
                    + this.getValue(keyValue.key)
                    + this.getValue(keyValue.value, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return keyValue;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
