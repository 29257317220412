import { DateTime } from "luxon";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { IRegistration } from "../../gyzmo-commons/interfaces/IEquipmentDto";
import { Registration } from "../models/registration.model";

export class RegistrationDto extends BaseDto<Registration> implements IRegistration {
    public id: string = "";
    public date: DateTime = null;
    public registration: string = "";

    public static fromBody(body: any): RegistrationDto {
        let registrationDto = new RegistrationDto();

        registrationDto.id = "" + body.id;
        registrationDto.date = DateHelper.tryFromFormat(body.dateRegistration, DATE_NODEJS_FORMAT);
        registrationDto.registration = "" + body.registration;

        return registrationDto;
    }

    static fromModel(registration: Registration): RegistrationDto {
        let registrationDto = new RegistrationDto();

        registrationDto.id = registration.id;
        registrationDto.date = DateTime.fromISO(registration.date);
        registrationDto.registration = registration.registration;

        return registrationDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.dateRegistration = DateHelper.tryToFormat(this.date, DATE_NODEJS_FORMAT);
        delete clone.date;

        return clone;
    }

    toModel() {
        let equipment = new Registration();

        equipment.id = this.id;
        equipment.date = DateHelper.tryToISO(this.date);
        equipment.registration = this.registration;

        return equipment;
    }
}

