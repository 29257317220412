import { DateTime } from "luxon";
import { DateHelper } from "../../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { BaseDto } from "../../../gyzmo-commons/dtos/base/base.dto";

export enum Side {
    Left = 1,
    Right = 2,
}

export class TyreDto extends BaseDto<void> {
    public elementId: string;
    public number: number = 0;
    public side: Side;
    public rank: number = 0;
    public size: string;
    public brand: string;
    public wear: number = 0;
    public entryDate: DateTime = null;

    // Volatile
    public part: number = 0;
    public axle: number = 0;
    public previousWear: number = 0;
    public previousBrand: string;
    public previousSize: string;

    public static fromBody(body: any, part: number, axle: number) {
        let result = new TyreDto();

        result.elementId = body.elementId;
        result.number = body.number;
        result.side = ("" + body.side).toLowerCase() == "left" ? Side.Left : Side.Right;
        result.rank = body.rank;

        result.size = body.size;
        result.previousSize = body.size;

        result.brand = body.brand;
        result.previousBrand = body.brand;

        result.wear = body.wear;
        result.previousWear = body.wear;

        result.entryDate = DateHelper.tryFromFormat(body.entryDate, DATE_NODEJS_FORMAT);

        result.part = part;
        result.axle = axle;

        return result;
    }

    public static fromModel(tyre): TyreDto {
        let result = new TyreDto();

        result.elementId = tyre.elementId;
        result.number = tyre.number;
        result.side = tyre.side == "1" ? Side.Left : Side.Right;
        result.rank = tyre.rank;

        result.size = tyre.size;
        result.previousSize = tyre.size;

        result.brand = tyre.brand;
        result.previousBrand = tyre.brand;

        result.wear = tyre.wear;
        result.previousWear = tyre.wear;

        result.entryDate = DateHelper.tryFromISO(tyre.entryDate);

        result.part = tyre.part;
        result.axle = tyre.axle;

        return result;
    }

    public toBody() {
        const clone: any = { ...this };

        delete clone.part;
        delete clone.axle;
        delete clone.previousSize;
        delete clone.previousBrand;
        delete clone.previousWear;

        clone.entryDate = DateHelper.tryToFormat(this.entryDate, DATE_NODEJS_FORMAT);
        clone.side = this.side == Side.Left ? "left" : "right";

        return clone;
    }

    public toModel(): void {
        throw new Error("Method not implemented.");
    }

    public isEqual(other: TyreDto) {
        return other.part == this.part
               && other.axle == this.axle
               && other.side == this.side
               && other.rank == this.rank;
    }
}

