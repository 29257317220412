import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { AttachmentKinds } from "../../../gyzmo-commons/interfaces/attachmentKinds";
import { Attachment } from "../../models/attachment.model";

@Injectable({
    providedIn: "root",
})
export class AttachmentDbDao extends DbDaoBase<Attachment> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Attachment.TABLENAME + "_id"
                    + " ON " + Attachment.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Attachment.TABLENAME + "_object"
                + " ON " + Attachment.TABLENAME + "(object);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Attachment.TABLENAME + "_key"
                + " ON " + Attachment.TABLENAME + "(key);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Attachment.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "object TEXT, "
                    + "key TEXT, "
                    + "wording TEXT, "
                    + "attachmentKind TEXT, "
                    + "date TEXT, "
                    + "file TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Attachment.TABLENAME + " WHERE id ='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Attachment.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    get(id: string, hydrate: boolean = false): Promise<Attachment> {
        let selectQuery = "SELECT * FROM " + Attachment.TABLENAME + " WHERE id = '" + id + "';";
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Attachment.TABLENAME;
    }

    protected rowToModel(row: any): Attachment {
        let attachment = new Attachment();
        attachment.id = row.id;
        attachment.object = row.object;
        attachment.key = row.key;
        attachment.wording = row.wording;
        attachment.date = row.date;
        attachment.file = row.file;

        attachment.attachmentKind = JSON.parse(row.attachmentKind);

        return attachment;
    }

    save(attachment: Attachment): Promise<Attachment> {
        let query = "INSERT OR REPLACE INTO " + Attachment.TABLENAME + " (id, object, key, wording, attachmentKind, date, file) VALUES ("
                    + this.getValue(attachment.id)
                    + this.getValue(attachment.object)
                    + this.getValue(attachment.key)
                    + this.getValue(attachment.wording)
                    + this.getValueAsJsonString(attachment.attachmentKind)
                    + this.getValue(attachment.date)
                    + this.getValue(attachment.file, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return attachment;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    getByObjectAndKey(object: string, key: string, attachmentKind?: AttachmentKinds): Promise<Attachment[]> {
        let selectQuery = "SELECT * FROM " + Attachment.TABLENAME + " WHERE object = '" + object + "' AND key = '" + key + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let attachments: Attachment[] = [];
                for (const item of data.rows) {
                    let attachment = this.rowToModel(item);
                    if (!attachmentKind || (attachmentKind && attachment.attachmentKind.id == attachmentKind)) {
                        attachments.push(attachment);
                    }
                }

                return attachments;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
