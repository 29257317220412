import { Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FileHelper } from "../../../helpers/file.helper";
import { AttachmentKinds } from "../../../interfaces/attachmentKinds";
import { IAttachmentDto } from "../../../interfaces/IAttachmentDto";

@Component({
    selector: "irium-gallery-document",
    templateUrl: "irium-gallery-document.html",
    styleUrls: ["irium-gallery-document.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => IriumGalleryDocument),
        },
    ],
})
export class IriumGalleryDocument implements ControlValueAccessor {
    @Input() disabled: boolean = false;
    @Input() canEdit: boolean = false;
    @Input() canDelete: boolean = false;
    @Input() selected: boolean = false;

    @Output() showClick: EventEmitter<IAttachmentDto> = new EventEmitter<IAttachmentDto>();
    @Output() editClick: EventEmitter<IAttachmentDto> = new EventEmitter<IAttachmentDto>();
    @Output() deleteClick: EventEmitter<IAttachmentDto> = new EventEmitter<IAttachmentDto>();

    value: IAttachmentDto;
    touched = false;
    protected readonly FileHelper = FileHelper;
    protected readonly AttachmentKinds = AttachmentKinds;

    public openDocument() {
        if (this.showClick) {
            this.showClick.emit(this.value);
        }
    }

    public editPicture() {
        if (this.editClick) {
            this.editClick.emit(this.value);
        }
    }

    public deleteDocument() {
        if (this.deleteClick) {
            this.deleteClick.emit(this.value);
        }
    }

    onChange = (value) => {
    };

    onTouched = () => {
    };

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any) {
        this.value = value;
    }

    markAsTouched() {
        this.onTouched();
        this.touched = true;
    }
}
