import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/notImplementedError";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class ThirdPartyWsDao extends WsDao<ThirdPartyDto> {
    static readonly WS = "third-parties";

    public getById(serverConnection: ServerConnection, id: string): Promise<ThirdPartyDto> {
        return new Promise<ThirdPartyDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, ThirdPartyWsDao.WS + "/:id", tokens)
                .then(response => {
                    let thirdParty = ThirdPartyDto.fromBody(response.body);

                    let promises = [];
                    Promise.all(promises)
                        .then(() => {
                            resolve(thirdParty);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, entity: ThirdPartyDto): Promise<ThirdPartyDto> {
        throw new NotImplementedError();
    }
}
