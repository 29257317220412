import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController, ViewDidEnter } from "@ionic/angular";
import { DeviceHelper } from "../../helpers/device.helper";
import { HammerJsPage } from "../../pages/hammerJs.page.base";

@Component({
    templateUrl: "view.modal.html",
    styleUrls: ["view.modal.scss"],
})
export class ViewModal extends HammerJsPage implements ViewDidEnter {
    @Input() imageData: string = "";

    constructor(router: Router,
                route: ActivatedRoute,
                deviceHelper: DeviceHelper,
                private modalController: ModalController) {
        super(router, route, deviceHelper);
    }

    ionViewDidEnter() {
        if (this.imageData.length > 0) {
            let image = new Image();
            image.src = this.imageData;
            image.onload = () => {
            };
        }

        this.initializeHammerJs();
    }

    public dismiss() {
        void this.modalController.dismiss();
    }
}
