import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    template: "",
})
export abstract class BasePage {
    protected constructor(protected router: Router,
                          protected route: ActivatedRoute) {
        // No need to unsubscribe this one, Router do it for us.
        this.route.queryParams.subscribe(() => {
            let publishStateUpdated = false;
            let currentNavigation = this.router.getCurrentNavigation();
            
            if (currentNavigation?.extras.queryParams) {
                let queryParams = currentNavigation.extras.queryParams;
                for (const field in queryParams) {
                    this[field] = queryParams[field];
                }

                publishStateUpdated = true;
            }

            if (currentNavigation?.extras.state) {
                let state = currentNavigation.extras.state;
                for (const field in state) {
                    this[field] = state[field];
                }

                publishStateUpdated = true;
            }

            if (publishStateUpdated) {
                this.onStateUpdated();
            }
        });
    }

    protected onStateUpdated() {
        // Override this to react to state updates
    }
}
