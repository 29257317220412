import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { IdWordingDbDao } from "../../../gyzmo-commons/dao/db/idWording.db.dao";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Equipment } from "../../models/equipment.model";

@Injectable({
    providedIn: "root",
})
export class EquipmentDbDao extends DbDaoBase<Equipment> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider,
                private idWordingDbDao: IdWordingDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Equipment.TABLENAME + "_id"
                    + " ON " + Equipment.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Equipment.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "internalId TEXT, "
                    + "title TEXT, "
                    + "category TEXT, "
                    + "model TEXT, "
                    + "brand TEXT, "
                    + "registrationNumber TEXT, "
                    + "serialNumber TEXT, "
                    + "mileage NUMERIC,"
                    + "mileageDate TEXT,"
                    + "mileage2 NUMERIC,"
                    + "mileageDate2 TEXT,"
                    + "inspectionModel TEXT,"
                    + "energy1 TEXT,"
                    + "energy2 TEXT,"
                    + "counter1 TEXT,"
                    + "counter2 TEXT,"
                    + "modelTyreWear TEXT,"
                    + "modelBrakeWear TEXT,"
                    + "wear TEXT,"
                    + "firstRegistrationDate TEXT,"
                    + "plannedDateOut TEXT,"
                    + "endOfWarrantyDate TEXT,"
                    + "type TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Equipment.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Equipment.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Equipment> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Equipment.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                let equipment: Equipment = this.rowToModel(data.rows[0]);

                let hydratationPromises = [];
                if (hydrate) {
                    hydratationPromises.push(this.idWordingDbDao.get(equipment.category.id, hydrate)
                        .then(value => {
                            equipment.category = value;
                        }));
                    hydratationPromises.push(this.idWordingDbDao.get(equipment.brand.id, hydrate)
                        .then(value => {
                            equipment.brand = value;
                        }));
                    hydratationPromises.push(this.idWordingDbDao.get(equipment.type.id, hydrate)
                        .then(value => {
                            equipment.type = value;
                        }));
                    hydratationPromises.push(this.idWordingDbDao.get(equipment.inspectionModel.id, hydrate)
                        .then(value => {
                            equipment.inspectionModel = value;
                        }));
                    hydratationPromises.push(this.idWordingDbDao.get(equipment.counter1.id, hydrate)
                        .then(value => {
                            equipment.counter1 = value;
                        }));
                    hydratationPromises.push(this.idWordingDbDao.get(equipment.counter2.id, hydrate)
                        .then(value => {
                            equipment.counter2 = value;
                        }));
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return equipment;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Equipment.TABLENAME;
    }

    protected rowToModel(row: any): Equipment {
        let equipment = new Equipment();

        equipment.id = row.id;
        equipment.internalId = row.internalId;
        equipment.title = row.title;
        equipment.category.id = row.category;
        equipment.model = row.model;
        equipment.brand.id = row.brand;
        equipment.registrationNumber = row.registrationNumber;
        equipment.serialNumber = row.serialNumber;

        equipment.mileage = Number(row.mileage);
        equipment.mileageDate = row.mileageDate;
        equipment.mileage2 = Number(row.mileage2);
        equipment.mileageDate2 = row.mileageDate2;

        equipment.firstRegistrationDate = row.firstRegistrationDate;
        equipment.plannedDateOut = row.plannedDateOut;
        equipment.endOfWarrantyDate = row.endOfWarrantyDate;

        equipment.inspectionModel.id = row.inspectionModel;

        equipment.energy1 = JSON.parse(row.energy1);
        equipment.energy2 = JSON.parse(row.energy2);
        equipment.counter1.id = row.counter1;
        equipment.counter2.id = row.counter2;

        equipment.modelTyreWear = row.modelTyreWear;
        equipment.modelBrakeWear = row.modelBrakeWear;
        equipment.type.id = row.type;

        equipment.wear = !isNullOrEmpty(row.wear) ? JSON.parse(row.wear) : [];

        return equipment;
    }

    public save(equipment: Equipment): Promise<Equipment> {
        let promises = [];

        if (equipment.category) {
            promises.push(this.idWordingDbDao.save(equipment.category));
        }

        if (equipment.brand) {
            promises.push(this.idWordingDbDao.save(equipment.brand));
        }

        if (equipment.type) {
            promises.push(this.idWordingDbDao.save(equipment.type));
        }

        if (equipment.inspectionModel) {
            promises.push(this.idWordingDbDao.save(equipment.inspectionModel));
        }

        if (equipment.counter1) {
            promises.push(this.idWordingDbDao.save(equipment.counter1));
        }

        if (equipment.counter2) {
            promises.push(this.idWordingDbDao.save(equipment.counter2));
        }

        return Promise.all(promises)
            .then(() => {
                let query = "INSERT OR REPLACE INTO " + Equipment.TABLENAME + " ("
                            + "id, internalId, title, category, model, brand, registrationNumber, serialNumber,"
                            + "mileage, mileageDate, mileage2, mileageDate2, inspectionModel,"
                            + "energy1, energy2, counter1, counter2, modelTyreWear, modelBrakeWear, wear, "
                            + "firstRegistrationDate, plannedDateOut, endOfWarrantyDate, type"
                            + ") VALUES ("
                            + this.getValue(equipment.id)
                            + this.getValue(equipment.internalId)
                            + this.getValue(equipment.title)
                            + this.getFkValue(equipment.category)
                            + this.getValue(equipment.model)
                            + this.getFkValue(equipment.brand)
                            + this.getValue(equipment.registrationNumber)
                            + this.getValue(equipment.serialNumber)
                            + this.getValue(equipment.mileage)
                            + this.getValue(equipment.mileageDate)
                            + this.getValue(equipment.mileage2)
                            + this.getValue(equipment.mileageDate2)
                            + this.getFkValue(equipment.inspectionModel)
                            + this.getValueAsJsonString(equipment.energy1)
                            + this.getValueAsJsonString(equipment.energy2)
                            + this.getFkValue(equipment.counter1)
                            + this.getFkValue(equipment.counter2)
                            + this.getValue(equipment.modelTyreWear)
                            + this.getValue(equipment.modelBrakeWear)
                            + this.getValueAsJsonString(equipment.wear)
                            + this.getValue(equipment.firstRegistrationDate)
                            + this.getValue(equipment.plannedDateOut)
                            + this.getValue(equipment.endOfWarrantyDate)
                            + this.getFkValue(equipment.type, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return equipment;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
