import { DateTime } from "luxon";
import { DateHelper } from "../../../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../../../gyzmo-commons/interfaces/constants";
import { Damage } from "../../../models/inspection/v2/damage.model";
import { AttachmentDto } from "../../attachment.dto";
import { BaseDto } from "../../../../gyzmo-commons/dtos/base/base.dto";

export class DamageDto extends BaseDto<Damage> {
    public id: string = "";
    public inspectionId: string = "";
    public zoneId: string = "";
    public elementId: string = "";
    public value: string = "";
    public clientResponsibility: string;
    public xAxis: number;
    public yAxis: number;
    public rowNumber: string = "";
    public updateDate: DateTime = null;

    public attachments: AttachmentDto[] = [];

    // internal
    public isOld: boolean;
    public isDeactivate: boolean;

    static fromBody(body: any, inspectionId: string, zoneId: string): DamageDto {
        let damageDto = new DamageDto();

        damageDto.id = "" + body.id;
        damageDto.inspectionId = inspectionId;
        damageDto.zoneId = zoneId;
        damageDto.elementId = body.elementId;
        damageDto.value = body.value;
        damageDto.clientResponsibility = body.clientResponsibility;
        damageDto.updateDate = DateHelper.tryFromFormat(body.updateDate, DATE_NODEJS_FORMAT);
        damageDto.xAxis = body.xAxis;
        damageDto.yAxis = body.yAxis;
        damageDto.rowNumber = body.rowNumber;
        damageDto.isOld = true;

        damageDto.isDeactivate = false;

        if (body.attachments) {
            body.attachments.forEach(attachment => {
                damageDto.attachments.push(AttachmentDto.fromBody(attachment, "damage", damageDto.id));
            });
        }

        return damageDto;
    }

    static fromModel(damage: Damage) {
        let damageDto = new DamageDto();

        damageDto.id = damage.id;
        damageDto.inspectionId = damage.inspectionId;
        damageDto.zoneId = damage.zoneId;
        damageDto.elementId = damage.elementId;
        damageDto.value = damage.value;
        damageDto.clientResponsibility = damage.clientResponsibility;
        damageDto.updateDate = DateTime.fromISO(damage.updateDate);
        damageDto.xAxis = damage.xAxis;
        damageDto.yAxis = damage.yAxis;
        damageDto.rowNumber = damage.rowNumber;
        damageDto.isOld = (damage.isOld == 1);

        damageDto.isDeactivate = (damage.isDeactivate == 1);

        if (damage.attachments) {
            damage.attachments.forEach(attachment => {
                damageDto.attachments.push(AttachmentDto.fromModel(attachment));
            });
        }

        return damageDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.updateDate = DateHelper.tryToFormat(this.updateDate, DATE_NODEJS_FORMAT);

        clone.attachments = [];
        this.attachments.forEach(attachment => {
            clone.attachments.push(attachment.toBody());
        });

        delete clone.isOld;
        delete clone.isDeactivate;

        return clone;
    }

    public toModel(): Damage {
        let damage = new Damage();

        damage.id = this.id;
        damage.inspectionId = this.inspectionId;
        damage.zoneId = this.zoneId;
        damage.elementId = this.elementId;
        damage.value = this.value;
        damage.clientResponsibility = this.clientResponsibility;
        damage.updateDate = DateHelper.tryToISO(this.updateDate);
        damage.xAxis = this.xAxis;
        damage.yAxis = this.yAxis;
        damage.rowNumber = this.rowNumber;
        damage.isOld = this.isOld ? 1 : 0;

        damage.isDeactivate = this.isDeactivate ? 1 : 0;

        if (this.attachments) {
            this.attachments.forEach(attachment => {
                damage.attachments.push(attachment.toModel());
            });
        }

        return damage;
    }
}
