// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.wrapper {
  min-height: 34px;
}

.with-bottom-border {
  margin: 0 20px 4px 40px;
  border-bottom: var(--with-bottom-border-color) thin solid;
}

ion-segment-button:not(.segment-button-checked)::part(indicator) {
  opacity: 1;
}

ion-segment-button:not(.segment-button-checked)::part(indicator-background) {
  height: 1px;
  background: var(--ion-color-light);
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/components/irium-select/irium-select.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,yDAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,WAAA;EACA,kCAAA;AACJ","sourcesContent":[":host {\r\n    display: block;\r\n}\r\n\r\n.wrapper {\r\n    min-height: 34px;\r\n}\r\n\r\n.with-bottom-border {\r\n    margin: 0 20px 4px 40px;\r\n    border-bottom: var(--with-bottom-border-color) thin solid;\r\n}\r\n\r\nion-segment-button:not(.segment-button-checked)::part(indicator) {\r\n    opacity: 1\r\n}\r\n\r\nion-segment-button:not(.segment-button-checked)::part(indicator-background) {\r\n    height: 1px;\r\n    background: var(--ion-color-light);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
