import { ServerConnection } from "src/core/http/serverConnection";
import { FtRecordDto } from "../../dto/ftRecord.dto";
import { TyreDto } from "../../dto/wear/tyres.dto";
import { WsDao } from "../../http/wsDao";

export class TyreWsDao extends WsDao<TyreDto> {
    public getBrandList(serverConnection: ServerConnection) {
        return new Promise<FtRecordDto[]>((resolve, reject) => {
            let url = "technical/ft?object=tyreBrand&_limit=NOLIMIT";

            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            result.push(FtRecordDto.fromBody(value, "tyreBrand"));
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getSizeList(serverConnection: ServerConnection) {
        return new Promise<FtRecordDto[]>((resolve, reject) => {
            let url = "technical/ft?object=tyreSize&_limit=NOLIMIT";

            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            result.push(FtRecordDto.fromBody(value, "tyreSize"));
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<TyreDto> {
        throw new Error("Method not implemented.");
    }

    public save(serverConnection: ServerConnection, entity: TyreDto): Promise<TyreDto> {
        throw new Error("Method not implemented.");
    }

}
