import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { IdWording } from "../../gyzmo-commons/models/idWording.model";
import { Location } from "./location.model";
import { ThirdParty } from "./thirdParty.model";

export class User extends ModelBase {
    static readonly TABLENAME = "Users";

    public id: string = "";
    public username: string = "";
    public thirdParty: ThirdParty = null;
    public language: string = "";
    public wording: string = "";
    public password: string = "";
    public company: IdWording = null;
    public profile: IdWording = null;
    public location: Location = null;
    public sector: IdWording = null;

    constructor() {
        super(User.TABLENAME);

        this.thirdParty = new ThirdParty();
        this.company = new IdWording();
        this.profile = new IdWording();
        this.location = new Location();
        this.sector = new IdWording();
    }
}
