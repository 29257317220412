import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController, Platform, ViewWillEnter } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AttachmentDto } from "../../../../../core/dto/attachment.dto";
import { DamageValueDto } from "../../../../../core/dto/inspection/common/damageValue.dto";
import { DamageDto } from "../../../../../core/dto/inspection/v2/damage.dto";
import { ZoneDto } from "../../../../../core/dto/inspection/v2/zone.dto";
import { ZoneElementDto } from "../../../../../core/dto/inspection/v2/zoneElement.dto";
import { AttachmentKinds } from "../../../../../gyzmo-commons/interfaces/attachmentKinds";
import { INSPECTION_KINDS } from "../../../../../core/interfaces/INSPECTION_KINDS";
import { AttachmentService } from "../../../../../core/services/attachment.service";
import { ZoneElementService } from "../../../../../core/services/inspection/v2/zoneElement.service";
import { LoadingHelper } from "../../../../../gyzmo-commons/helpers/loading.helper";
import { NetworkHelper } from "../../../../../gyzmo-commons/helpers/network.helper";
import { isNullOrEmpty } from "../../../../../gyzmo-commons/helpers/null.helper";
import { ToastHelper } from "../../../../../gyzmo-commons/helpers/toast.helper";
import { DateProvider } from "../../../../../gyzmo-commons/interfaces/dateProvider";
import { FormValidationBasePage } from "../../../../../gyzmo-commons/pages/formValidation.base.page";
import { LoggerService } from "../../../../../gyzmo-commons/services/logs/logger.service";

@Component({
    selector: "modal-create-damage",
    templateUrl: "create-damage.modal.html",
    styleUrls: ["create-damage.modal.scss"],
})
export class CreateDamageModal extends FormValidationBasePage implements ViewWillEnter {
    @Input() zone: ZoneDto;
    @Input() damage: DamageDto;
    @Input() inspectionKind: string;
    @Input() isCreation: boolean;

    subscription: Subscription;
    inspectionKinds = INSPECTION_KINDS;

    isAttachmentDownloaded = false;

    zoneElements: ZoneElementDto[] = null;
    possibleValues: DamageValueDto[] = null;
    responsibility: { id: string; wording: any } [];

    constructor(router: Router,
                route: ActivatedRoute,
                logger: LoggerService,
                private platform: Platform,
                private viewCtrl: ModalController,
                private loadingHelper: LoadingHelper,
                private attachmentService: AttachmentService,
                private networkHelper: NetworkHelper,
                private translateService: TranslateService,
                private toastHelper: ToastHelper,
                private zoneElementService: ZoneElementService,
                private dateProvider: DateProvider) {
        super(router, route, logger);
    }

    public dismiss() {
        this.subscription.unsubscribe();
        void this.viewCtrl.dismiss({ damage: this.damage, action: "cancel" });
    }

    public getTxtAttachment(): AttachmentDto {
        let foundAttachments = this.damage.attachments.filter(value => {
            return value.attachmentKind.id == AttachmentKinds.TEXTE;
        });

        if (foundAttachments.length > 0) {
            return foundAttachments[0];
        }

        return null;
    }

    public saveAndDismiss() {
        if (this.isFormValid()) {
            let element = this.getFormValue("element");
            this.damage.elementId = element;
            let damage = this.getFormValue("damage");
            this.damage.value = damage;
            let responsibility = this.getFormValue("responsibility");
            this.damage.clientResponsibility = responsibility;

            let comment = this.getFormValue("comment");
            if (!isNullOrEmpty(comment)) {
                let attachment: AttachmentDto = this.getTxtAttachment();
                if (!attachment) {
                    attachment = new AttachmentDto();
                    this.damage.attachments.push(attachment);
                }

                attachment.object = this.damage.inspectionId;
                attachment.modified = true;
                attachment.date = this.dateProvider.now();
                attachment.attachmentKind.id = AttachmentKinds.TEXTE;
                attachment.txt = comment;
            }

            void this.viewCtrl.dismiss({ damage: this.damage, action: "save" });
        }
    }

    public delete() {
        void this.viewCtrl.dismiss({ damage: this.damage, action: "delete" });
    }

    public deactivate() {
        void this.viewCtrl.dismiss({ damage: this.damage, action: "deactivate" });
    }

    public reactivate() {
        void this.viewCtrl.dismiss({ damage: this.damage, action: "reactivate" });
    }

    getPossibleValues(): DamageValueDto[] {
        try {
            let elementId: string = this.getFormValue("element");
            const elementDto = this.zoneElements.find(value => {
                return value.id == elementId;
            });

            return elementDto.damageCategory.possibleValues.sort((a, b) => {
                return a.order.localeCompare(b.order);
            });
        } catch {
            return [];
        }
    }

    public async onDownloadAttachmentsClick() {
        if (this.networkHelper.isConnected()) {
            await this.loadingHelper.showLoading();

            this.damage.attachments = await this.attachmentService.getByDamageId(this.damage.id, AttachmentKinds.PHOTO);
            this.getFormControl("photos").setValue(this.damage.attachments);

            this.isAttachmentDownloaded = true;

            await this.loadingHelper.hideAll();
        } else {
            await this.toastHelper.show(this.translateService.instant("IONIC_Action_impossible_you_are_not_under_network_cover."));
        }
    }

    public onElementChanged() {
        this.possibleValues = this.getPossibleValues();
    }

    protected async createFormGroup(): Promise<FormGroup<any>> {
        this.zoneElements = await this.zoneElementService.getByZoneId(this.zone.id);

        if (this.zoneElements && this.zoneElements.length == 1) {
            this.damage.elementId = this.zoneElements[0].id;
        }

        let controls: any = {};
        if (this.zoneElements.length > 1) {
            controls.element = new FormControl(this.damage.elementId, [Validators.required]);
        } else {
            controls.element = new FormControl(this.damage.elementId, []);
        }

        if (this.damage.isOld) {
            controls.damage = new FormControl(this.damage.value, []);
        } else {
            controls.damage = new FormControl(this.damage.value, [Validators.required]);
        }
        controls.responsibility = new FormControl(this.damage.clientResponsibility, []);
        controls.comment = new FormControl(this.getTxtAttachment()?.txt, []);
        controls.photos = new FormControl(this.damage.attachments, []);

        let formGroup = new FormGroup(controls);
        formGroup.get("element").valueChanges.subscribe(value => {
            this.possibleValues = this.getPossibleValues();

            if (!this.damage.isOld) {
                formGroup.get("damage").setValue(null);
            }
        });

        if (this.damage.isOld) {
            formGroup.get("element").disable();
            formGroup.get("damage").disable();
            formGroup.get("responsibility").disable();
            formGroup.get("comment").disable();
        }

        return formGroup;
    }

    async ionViewWillEnter() {
        await super.ionViewWillEnter();

        this.subscription = this.platform.backButton.subscribeWithPriority(999, () => {
            this.dismiss();
        });

        this.responsibility = [
            { id: "0", wording: this.translateService.instant("IONIC_Undefined") },
            { id: "1", wording: this.translateService.instant("IONIC_Customer") },
            { id: "2", wording: this.translateService.instant("IONIC_Rental_company") },
        ];
    }
}
