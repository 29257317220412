import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";
import { Zone } from "./zone.model";

export class Face extends ModelBase {
    static readonly TABLENAME = "Faces_V2";

    public id: string = "";
    public wording: string = "";
    public order: string = "";

    public zones: Zone[] = [];

    constructor() {
        super(Face.TABLENAME);
    }
}
