import { IAddressDto } from "../../gyzmo-commons/helpers/address.helper";
import { Address } from "../models/address.model";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";

export class AddressDto extends BaseDto<Address> implements IAddressDto {
    public id: string = "";
    public address1: string = "";
    public address2: string = "";
    public address3: string = "";
    public address4: string = "";
    public city: string = "";
    public zipCode: string = "";

    public static fromBody(body: any): AddressDto {
        let addressDto = new AddressDto();

        addressDto.id = "" + body.id;
        addressDto.address1 = body.address1;
        addressDto.address2 = body.address2;
        addressDto.address3 = body.address3;
        addressDto.address4 = body.address4;
        addressDto.zipCode = body.zipCode;
        addressDto.city = body.city;

        return addressDto;
    }

    public static fromModel(model: Address): AddressDto {
        let addressDto = new AddressDto();

        addressDto.id = model.id;
        addressDto.address1 = model.address1;
        addressDto.address2 = model.address2;
        addressDto.address3 = model.address3;
        addressDto.address4 = model.address4;
        addressDto.zipCode = model.zipCode;
        addressDto.city = model.city;

        return addressDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    toModel(): Address {
        let addressDto = new Address();

        addressDto.id = this.id;
        addressDto.address1 = this.address1;
        addressDto.address2 = this.address2;
        addressDto.address3 = this.address3;
        addressDto.address4 = this.address4;
        addressDto.zipCode = this.zipCode;
        addressDto.city = this.city;

        return addressDto;
    }
}
