import { Injectable } from "@angular/core";
import { CustomisationDto } from "../../dto/customisation.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class CustomisationWsDao extends WsDao<CustomisationDto> {
    static readonly WS = "technical/front-repository";

    public get(serverConnection: ServerConnection, companyId: string): Promise<CustomisationDto> {
        return new Promise<any>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", companyId);

            serverConnection.get(this.constructor.name, CustomisationWsDao.WS + "/?objectFrontRepository=technical-customisation&id=:id", tokens)
                .then(response => {
                    resolve(CustomisationDto.fromBody(response.body));
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<CustomisationDto> {
        throw new Error("Not implemented");
    }

    public save(serverConnection: ServerConnection, customisation: CustomisationDto): Promise<CustomisationDto> {
        throw new Error("Not implemented");
    }
}
