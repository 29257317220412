import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class AttachmentKind extends ModelBase {
    static readonly TABLENAME = "AttachmentKinds";
    public id: string = "";
    public wording: string = "";

    constructor() {
        super(AttachmentKind.TABLENAME);
    }
}
