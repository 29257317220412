import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { IdWording } from "../../gyzmo-commons/models/idWording.model";
import { Registration } from "./registration.model";

export class Equipment extends ModelBase {
    static readonly TABLENAME = "Equipments";

    public id: string = "";
    public internalId: string = "";
    public title: string = "";
    public category: IdWording = null;
    public model: string = "";
    public brand: IdWording = null;
    public type: IdWording = null;
    public registration: Registration = null;
    public serialNumber: string = "";

    public modelBrakeWear: string = "";
    public modelTyreWear: string = "";

    public mileage: number = 0;
    public mileageDate: string = "";
    public mileage2: number = 0;
    public mileageDate2: string = "";

    public plannedDateOut: string = "";
    public customerWarrantyDate: string = "";
    public manufacturerWarrantyDate: string = "";

    public inspectionModel: IdWording = null;

    public wear: any[] = [];

    // Personal model
    public energy1: { id?: string, wording?: string, fraction?: number } = {};
    public energy2: { id?: string, wording?: string, fraction?: number } = {};
    public counter1: IdWording = null;
    public counter2: IdWording = null;

    public reference4: string;
    public reference5: string;
    public reference6: string;

    constructor() {
        super(Equipment.TABLENAME);

        this.registration = new Registration();
        this.category = new IdWording();
        this.brand = new IdWording();
        this.type = new IdWording();
        this.counter1 = new IdWording();
        this.counter2 = new IdWording();
        this.inspectionModel = new IdWording();
    }
}

