import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../../gyzmo-commons/helpers/pipes/pipes.module";
import { MaintenanceControlHelpPopover } from "./maintenance-control-help.popover";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: "",
                component: MaintenanceControlHelpPopover,
            },
        ]),
        PipesModule,
        TranslateModule,
    ],
    declarations: [MaintenanceControlHelpPopover],
    providers: [],
})
export class MaintenanceControlHelpModule {
}
