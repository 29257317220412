import { Injectable } from "@angular/core";
import { MaintenanceControlsDbDao } from "../dao/db/maintenanceControls.db.dao";
import { MaintenanceControlsWsDao } from "../dao/ws/maintenanceControls.ws.dao";
import { MaintenanceControlDto } from "../dto/maintenanceControl.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class MaintenanceControlsService {
    constructor(private maintenanceControlsWsDao: MaintenanceControlsWsDao,
                private maintenanceControlsDbDao: MaintenanceControlsDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async synchronizeForEquipment(equipmentId: string): Promise<MaintenanceControlDto[]> {
        let maintenanceControls = await this.maintenanceControlsWsDao.listByEquipment(this.serversConnectionsProvider.getPrimaryServerConnection(),
            equipmentId);

        for (let maintenanceControl of maintenanceControls) {
            await this.maintenanceControlsDbDao.save(maintenanceControl.toModel());
        }

        return maintenanceControls;
    }

    public async listForEquipmentOffline(equipmentId: string): Promise<MaintenanceControlDto[]> {
        let maintenanceControls = await this.maintenanceControlsDbDao.listForEquipment(equipmentId);

        return maintenanceControls.map(maintenanceControl => {
            return MaintenanceControlDto.fromModel(maintenanceControl);
        });
    }
}
