import { DateTime } from "luxon";
import { DEFAULT_MAX_TANK_CAPACITY } from "../../app/app.constants";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";
import { IdWordingDto } from "../../gyzmo-commons/dtos/idWording.dto";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { IEquipmentSpecificDto } from "../interfaces/IEquipmentSpecificDto";
import { Equipment } from "../models/equipment.model";
import { RegistrationDto } from "./registration.dto";
import { VehiclePartDto } from "./wear/vehiclePart.dto";

export enum CounterKinds {
    None = "0",
    Kilometers = "1",
    Hour = "3",
}

export class EquipmentDto extends BaseDto<Equipment> implements IEquipmentSpecificDto {
    public category: IdWordingDto = null;
    public model: string = "";
    public brand: IdWordingDto = null;
    public id: string = "";
    public internalId: string = "";
    public registration: RegistrationDto = null;
    public serialNumber: string = "";
    public title: string = "";
    public type: IdWordingDto = null;
    public modelBrakeWear: string = "";
    public modelTyreWear: string = "";
    public mileage: number = 0;
    public mileageDate: DateTime = null;
    public mileage2: number = 0;
    public mileageDate2: DateTime = null;
    public plannedDateOut: DateTime = null;
    public customerWarrantyDate: DateTime = null;
    public manufacturerWarrantyDate: DateTime = null;
    public inspectionModel: IdWordingDto = null;
    public wear: VehiclePartDto[] = [];

    public reference4: string;
    public reference5: string;
    public reference6: string;

    // Personal model
    public energy1: { id?: string, wording?: string, fraction?: number } = {};
    public energy2: { id?: string, wording?: string, fraction?: number } = {};
    public counter1: IdWordingDto = null;
    public counter2: IdWordingDto = null;

    // Volatile
    public inspectionInProgress: { id?: string, visitDate?: DateTime } = {};
    public previousMileage: number = 0;
    public previousMileage2: number = 0;
    public previousPlannedDateOut: DateTime = null;
    public wearDefinitionModified = false;

    constructor() {
        super();

        this.category = new IdWordingDto();
        this.registration = new RegistrationDto();
        this.brand = new IdWordingDto();
        this.type = new IdWordingDto();
        this.counter1 = new IdWordingDto();
        this.counter2 = new IdWordingDto();
        this.inspectionModel = new IdWordingDto();
    }

    public static fromBody(body: any): EquipmentDto {
        let equipmentDto = new EquipmentDto();

        equipmentDto.id = "" + body.id;
        if (body.internalId) {
            equipmentDto.internalId = "" + body.internalId;
        }
        equipmentDto.title = body.wording.trim();
        if (body.model) {
            equipmentDto.model = body.model.id;
        }

        if (body.registration) {
            equipmentDto.registration = RegistrationDto.fromBody(body.registration);
        }

        equipmentDto.serialNumber = body.serialNumber;

        if (body.personalModel) {
            if (body.personalModel.category) {
                equipmentDto.category = IdWordingDto.fromBody(body.personalModel.category);
            }

            if (body.personalModel.brand) {
                equipmentDto.brand = IdWordingDto.fromBody(body.personalModel.brand);
            }

            if (body.personalModel.counterKind) {
                if (body.personalModel.counterKind.id) {
                    equipmentDto.counter1.id = body.personalModel.counterKind.id;
                    equipmentDto.counter1.wording = body.personalModel.counterKind.wording;
                } else {
                    equipmentDto.counter1.id = "";
                    equipmentDto.counter1.wording = "";
                }
            }

            if (body.personalModel.counterKind2) {
                if (body.personalModel.counterKind2.id) {
                    equipmentDto.counter2.id = body.personalModel.counterKind2.id;
                    equipmentDto.counter2.wording = body.personalModel.counterKind2.wording;
                } else {
                    equipmentDto.counter2.id = "";
                    equipmentDto.counter2.wording = "";
                }
            }

            if (body.personalModel.gaugeKind) {
                if (body.personalModel.gaugeKind.fraction) {
                    equipmentDto.energy1.fraction = Number(body.personalModel.gaugeKind.fraction);
                } else {
                    equipmentDto.energy1.fraction = DEFAULT_MAX_TANK_CAPACITY;
                }
            }

            if (body.personalModel.gaugeKind2) {
                if (body.personalModel.gaugeKind2.fraction) {
                    equipmentDto.energy2.fraction = Number(body.personalModel.gaugeKind2.fraction);
                } else {
                    equipmentDto.energy2.fraction = DEFAULT_MAX_TANK_CAPACITY;
                }
            }

            if (body.personalModel.energy) {
                equipmentDto.energy1.id = body.personalModel.energy.id;
                equipmentDto.energy1.wording = body.personalModel.energy.wording;
            }

            if (body.personalModel.energy2) {
                equipmentDto.energy2.id = body.personalModel.energy2.id;
                equipmentDto.energy2.wording = body.personalModel.energy2.wording;
            }

            if (body.personalModel.inspectionModel?.id) {
                equipmentDto.inspectionModel = IdWordingDto.fromBody(body.personalModel.inspectionModel);
            }

            if (body.personalModel.modelTyreWear?.id) {
                equipmentDto.modelTyreWear = body.personalModel.modelTyreWear.id;
            }
            if (body.personalModel.modelBrakeWear?.id) {
                equipmentDto.modelBrakeWear = body.personalModel.modelBrakeWear.id;
            }
        }

        equipmentDto.mileage = Number(body.mileageValue);
        equipmentDto.previousMileage = equipmentDto.mileage;
        equipmentDto.mileageDate = DateHelper.tryFromFormat(body.mileageDate, DATE_NODEJS_FORMAT);

        equipmentDto.mileage2 = Number(body.mileageValue2);
        equipmentDto.previousMileage2 = equipmentDto.mileage2;
        equipmentDto.mileageDate2 = DateHelper.tryFromFormat(body.mileageDate2, DATE_NODEJS_FORMAT);

        equipmentDto.customerWarrantyDate = DateHelper.tryFromFormat(body.customerWarrantyDate, DATE_NODEJS_FORMAT);
        equipmentDto.plannedDateOut = DateHelper.tryFromFormat(body.previsionnalDateOut, DATE_NODEJS_FORMAT);
        equipmentDto.previousPlannedDateOut = DateHelper.tryFromFormat(body.previsionnalDateOut, DATE_NODEJS_FORMAT);
        equipmentDto.manufacturerWarrantyDate = DateHelper.tryFromFormat(body.manufacturerWarrantyDate, DATE_NODEJS_FORMAT);

        equipmentDto.reference4 = body.reference4;
        equipmentDto.reference5 = body.reference5;
        equipmentDto.reference6 = body.reference6;

        if (body.inspectionInProgress) {
            equipmentDto.inspectionInProgress = {};
            equipmentDto.inspectionInProgress.id = body.inspectionInProgress.id;
            equipmentDto.inspectionInProgress.visitDate = DateHelper.tryFromFormat(body.inspectionInProgress.visitDate, DATE_NODEJS_FORMAT);
        }

        if (body.inspectionModel?.id) {
            equipmentDto.inspectionModel = IdWordingDto.fromBody(body.inspectionModel);
        }

        if (body.type) {
            equipmentDto.type = IdWordingDto.fromBody(body.type);
        }

        return equipmentDto;
    }

    static fromModel(equipment: Equipment): EquipmentDto {
        let equipmentDto = new EquipmentDto();

        equipmentDto.id = equipment.id;
        equipmentDto.internalId = equipment.internalId;
        equipmentDto.title = equipment.title;
        equipmentDto.category = IdWordingDto.fromModel(equipment.category);
        equipmentDto.model = equipment.model;
        equipmentDto.brand = IdWordingDto.fromModel(equipment.brand);
        equipmentDto.registration = RegistrationDto.fromModel(equipment.registration);
        equipmentDto.serialNumber = equipment.serialNumber;

        equipmentDto.mileage = equipment.mileage;
        equipmentDto.previousMileage = equipment.mileage;
        equipmentDto.mileageDate = DateTime.fromISO(equipment.mileageDate);
        equipmentDto.mileage2 = equipment.mileage2;
        equipmentDto.previousMileage2 = equipment.mileage2;
        equipmentDto.mileageDate2 = DateTime.fromISO(equipment.mileageDate2);

        equipmentDto.customerWarrantyDate = DateTime.fromISO(equipment.customerWarrantyDate);
        equipmentDto.plannedDateOut = DateTime.fromISO(equipment.plannedDateOut);
        equipmentDto.previousPlannedDateOut = DateTime.fromISO(equipment.plannedDateOut);
        equipmentDto.manufacturerWarrantyDate = DateTime.fromISO(equipment.manufacturerWarrantyDate);

        equipmentDto.inspectionModel = IdWordingDto.fromModel(equipment.inspectionModel);

        equipmentDto.energy1 = equipment.energy1;
        equipmentDto.energy2 = equipment.energy2;

        equipmentDto.counter1 = IdWordingDto.fromModel(equipment.counter1);
        equipmentDto.counter2 = IdWordingDto.fromModel(equipment.counter2);

        equipmentDto.modelTyreWear = equipment.modelTyreWear;
        equipmentDto.modelBrakeWear = equipment.modelBrakeWear;
        equipmentDto.type = IdWordingDto.fromModel(equipment.type);

        equipmentDto.reference4 = equipment.reference4;
        equipmentDto.reference5 = equipment.reference5;
        equipmentDto.reference6 = equipment.reference6;

        let number = 1;
        equipmentDto.wear = [];
        equipment.wear.forEach(wear => {
            equipmentDto.wear.push(VehiclePartDto.fromModel(wear));
            number++;
        });

        return equipmentDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        // Nettoyer l'object avant envoi a lpservice
        delete clone.picture;

        delete clone.previousMileage;
        delete clone.previousMileage2;
        delete clone.previousPlannedDateOut;
        delete clone.wear;

        clone.mileageDate = DateHelper.tryToFormat(this.mileageDate, DATE_NODEJS_FORMAT);
        clone.mileageDate2 = DateHelper.tryToFormat(this.mileageDate2, DATE_NODEJS_FORMAT);
        clone.plannedDateOut = DateHelper.tryToFormat(this.plannedDateOut, DATE_NODEJS_FORMAT);
        clone.customerWarrantyDate = DateHelper.tryToFormat(this.customerWarrantyDate, DATE_NODEJS_FORMAT);
        clone.manufacturerWarrantyDate = DateHelper.tryToFormat(this.manufacturerWarrantyDate, DATE_NODEJS_FORMAT);

        clone.registration = this.registration.toBody();
        clone.category = this.category.toBody();
        clone.brand = this.brand.toBody();
        clone.type = this.type.toBody();
        clone.counter1 = this.counter1.toBody();
        clone.counter2 = this.counter2.toBody();
        clone.inspectionModel = this.inspectionModel.toBody();

        return clone;
    }

    toModel() {
        let equipment = new Equipment();

        equipment.id = this.id;
        equipment.internalId = this.internalId;
        equipment.title = this.title;
        equipment.category = this.category.toModel();
        equipment.model = this.model;
        equipment.brand = this.brand.toModel();
        equipment.registration = this.registration.toModel();
        equipment.serialNumber = this.serialNumber;

        equipment.mileage = this.mileage;
        equipment.mileageDate = DateHelper.tryToISO(this.mileageDate);
        equipment.mileage2 = this.mileage2;
        equipment.mileageDate2 = DateHelper.tryToISO(this.mileageDate2);

        equipment.plannedDateOut = DateHelper.tryToISO(this.plannedDateOut);

        equipment.customerWarrantyDate = DateHelper.tryToISO(this.customerWarrantyDate);
        equipment.manufacturerWarrantyDate = DateHelper.tryToISO(this.manufacturerWarrantyDate);

        equipment.inspectionModel = this.inspectionModel.toModel();

        equipment.energy1 = this.energy1;
        equipment.energy2 = this.energy2;

        equipment.counter1 = this.counter1.toModel();
        equipment.counter2 = this.counter2.toModel();

        equipment.modelTyreWear = this.modelTyreWear;
        equipment.modelBrakeWear = this.modelBrakeWear;
        equipment.type = this.type.toModel();

        equipment.reference4 = this.reference4;
        equipment.reference5 = this.reference5;
        equipment.reference6 = this.reference6;

        equipment.wear = this.wear;

        return equipment;
    }
}

