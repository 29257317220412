import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class Company extends ModelBase {
    static readonly TABLENAME = "Companies";
    public id: string = "";
    public wording: string = "";
    public phone: string = "";
    public mail: string = "";

    constructor() {
        super(Company.TABLENAME);
    }
}
