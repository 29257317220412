import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";
import { IdWordingDto } from "../../gyzmo-commons/dtos/idWording.dto";
import { IUserDto } from "../../gyzmo-commons/interfaces/IUserDto";
import { User } from "../models/user.model";
import { LocationDto } from "./location.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class UserDto extends BaseDto<User> implements IUserDto {
    public id: string = "";
    public username: string = "";
    public thirdParty: ThirdPartyDto = null;
    public language: string = "";
    public wording: string = "";
    public password: string = "";
    public company: IdWordingDto = null;
    public profile: IdWordingDto = null;
    public location: LocationDto = null;
    public sector: IdWordingDto = null;

    constructor() {
        super();

        this.thirdParty = new ThirdPartyDto();
        this.location = new LocationDto();
        this.sector = new IdWordingDto();

        this.company = new IdWordingDto();
        this.profile = new IdWordingDto();
    }

    public static fromBody(body: any): UserDto {
        let user = new UserDto();

        user.id = "" + body.id;
        user.username = body.username;
        user.language = body.language;
        user.wording = body.wording;
        user.password = body.password;

        if (body.company) {
            user.company = IdWordingDto.fromBody(body.company);
        }

        if (body.profile) {
            user.profile = IdWordingDto.fromBody(body.profile);
        }

        if (body.thirdParty) {
            user.thirdParty = ThirdPartyDto.fromBody(body.thirdParty);
        }

        if (body.location) {
            user.location = LocationDto.fromBody(body.location);
        }

        if (body.sector) {
            user.sector = IdWordingDto.fromBody(body.sector);
        }

        return user;
    }

    static fromModel(user: User): UserDto {
        let userDto = new UserDto();

        userDto.id = user.id;
        userDto.username = user.username;
        userDto.language = user.language;
        userDto.wording = user.wording;
        userDto.password = user.password;

        if (user.company) {
            userDto.company = IdWordingDto.fromModel(user.company);
        }
        if (user.profile) {
            userDto.profile = IdWordingDto.fromModel(user.profile);
        }

        if (user.location) {
            userDto.location = LocationDto.fromModel(user.location);
        }

        if (user.thirdParty) {
            userDto.thirdParty = ThirdPartyDto.fromModel(user.thirdParty);
        }
        if (user.sector) {
            userDto.sector = IdWordingDto.fromModel(user.sector);
        }

        return userDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.thirdParty = this.thirdParty.toBody();
        clone.location = this.location.toBody();
        clone.sector = this.sector.toBody();
        clone.company = this.sector.toBody();
        clone.profile = this.sector.toBody();

        return clone;
    }

    toModel(): User {
        let user = new User();

        user.id = this.id;
        user.username = this.username;
        user.language = this.language;
        user.wording = this.wording;
        user.password = this.password;

        user.thirdParty = this.thirdParty.toModel();
        user.location = this.location.toModel();
        user.sector = this.sector.toModel();
        user.company = this.company.toModel();
        user.profile = this.profile.toModel();

        return user;
    }
}
