import { Injectable } from "@angular/core";
import { IServerConnection } from "../interfaces/IServerConnection";
import { ThirdPartyType } from "../models/thirdPartyType.model";

@Injectable({
    providedIn: "root",
})
export class ThirdPartyTypesWs {
    static readonly WS = "third-parties/:id/third-party-types";

    public getListByThirdPartyId(serverConnection: IServerConnection, thirdPartyId: string): Promise<ThirdPartyType[]> {
        return new Promise<ThirdPartyType[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", thirdPartyId);

            serverConnection.get(this.constructor.name, ThirdPartyTypesWs.WS, tokens, false, false, true)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let thirdPartyType = this.responseBodyToModel(value);
                            result.push(thirdPartyType);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    responseBodyToModel(body: any): ThirdPartyType {
        let thirdPartyType = new ThirdPartyType();
        thirdPartyType.id = body.thirdPartyType.id;

        return thirdPartyType;
    }
}
