import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { MaintenanceControl } from "../models/maintenanceControl.model";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";

export class MaintenanceControlDto extends BaseDto<MaintenanceControl> {
    public id: string = "";
    public equipmentId: string = "";
    public wording: string = "";
    public plannedMinDate: DateTime = null;
    public plannedDate: DateTime = null;
    public plannedMaxDate: DateTime = null;
    public order: number = 0;

    public static fromBody(body: any, equipmentId: string): MaintenanceControlDto {
        let maintenanceControlDto = new MaintenanceControlDto();

        maintenanceControlDto.id = "" + body.id;
        maintenanceControlDto.equipmentId = "" + equipmentId;
        maintenanceControlDto.wording = body.wording;
        if (body.order) {
            maintenanceControlDto.order = body.order;
        }

        maintenanceControlDto.plannedMinDate = DateHelper.tryFromFormat(body.plannedMinDate, DATE_NODEJS_FORMAT);
        maintenanceControlDto.plannedDate = DateHelper.tryFromFormat(body.plannedDate, DATE_NODEJS_FORMAT);
        maintenanceControlDto.plannedMaxDate = DateHelper.tryFromFormat(body.plannedMaxDate, DATE_NODEJS_FORMAT);

        return maintenanceControlDto;
    }

    public static fromModel(maintenanceControl: MaintenanceControl): MaintenanceControlDto {
        let maintenanceControlDto = new MaintenanceControlDto();

        maintenanceControlDto.id = maintenanceControl.id;
        maintenanceControlDto.equipmentId = maintenanceControl.equipmentId;
        maintenanceControlDto.wording = maintenanceControl.wording;
        maintenanceControlDto.order = maintenanceControl.order;

        maintenanceControlDto.plannedMinDate = DateHelper.tryFromISO(maintenanceControl.plannedMinDate);
        maintenanceControlDto.plannedDate = DateHelper.tryFromISO(maintenanceControl.plannedDate);
        maintenanceControlDto.plannedMaxDate = DateHelper.tryFromISO(maintenanceControl.plannedMaxDate);

        return maintenanceControlDto;
    }

    public toBody() {
        const clone: any = { ...this };

        clone.plannedMinDate = DateHelper.tryToFormat(this.plannedMinDate, DATE_NODEJS_FORMAT);
        clone.plannedDate = DateHelper.tryToFormat(this.plannedDate, DATE_NODEJS_FORMAT);
        clone.plannedMaxDate = DateHelper.tryToFormat(this.plannedMaxDate, DATE_NODEJS_FORMAT);

        return clone;
    }

    public toModel(): MaintenanceControl {
        let maintenanceControl = new MaintenanceControl();

        maintenanceControl.id = this.id;
        maintenanceControl.equipmentId = this.equipmentId;
        maintenanceControl.wording = this.wording;
        maintenanceControl.order = this.order;

        maintenanceControl.plannedMinDate = DateHelper.tryToISO(this.plannedMinDate);
        maintenanceControl.plannedDate = DateHelper.tryToISO(this.plannedDate);
        maintenanceControl.plannedMaxDate = DateHelper.tryToISO(this.plannedMaxDate);

        return maintenanceControl;
    }
}
