import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { NotImplementedError } from "../../../../../gyzmo-commons/helpers/notImplementedError";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../../../gyzmo-commons/services/logs/logger.service";
import { ZoneElement } from "../../../../models/inspection/v2/zoneElement.model";
import { DamageCategoryDbDao } from "../common/damageCategory.db.dao";

@Injectable({
    providedIn: "root",
})
export class ZoneElementDbDaoV2 extends DbDaoBase<ZoneElement> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider,
                private damageCategoryDbDao: DamageCategoryDbDao) {
        super(logger);
    }

    getByZoneId(zoneId: string, hydrate: boolean = true): Promise<ZoneElement[]> {
        let selectQuery = "SELECT * FROM " + ZoneElement.TABLENAME + " WHERE parentZoneId = '" + zoneId + "';";

        return this.sqlProvider.query(selectQuery)
            .then(async data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let zoneElements: ZoneElement[] = [];
                for (const item of data.rows) {
                    let zoneElement = this.rowToModel(item);
                    zoneElements.push(zoneElement);
                }

                let hydratationPromises = [];
                if (hydrate) {
                    zoneElements.forEach(zoneElement => {
                        hydratationPromises.push(this.damageCategoryDbDao.get(zoneElement.damageCategory.id, hydrate)
                            .then(value => {
                                zoneElement.damageCategory = value;
                            }));
                    });
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return zoneElements;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getList(hydrate: boolean = true): Promise<ZoneElement[]> {
        let selectQuery = "SELECT * FROM " + ZoneElement.TABLENAME + ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let zoneElements: ZoneElement[] = [];
                for (const item of data.rows) {
                    let zoneElement = this.rowToModel(item);
                    zoneElements.push(zoneElement);
                }

                let hydratationPromises = [];
                if (hydrate) {
                    zoneElements.forEach(zoneElement => {
                        hydratationPromises.push(this.damageCategoryDbDao.get(zoneElement.damageCategory.id, hydrate)
                            .then(value => {
                                zoneElement.damageCategory = value;
                            }));
                    });
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return zoneElements;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + ZoneElement.TABLENAME + "_key"
                    + " ON " + ZoneElement.TABLENAME + "(key);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + ZoneElement.TABLENAME + "_id"
                + " ON " + ZoneElement.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + ZoneElement.TABLENAME + "_parentZoneId"
                + " ON " + ZoneElement.TABLENAME + "(parentZoneId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + ZoneElement.TABLENAME
                    + " ("
                    + "key TEXT PRIMARY KEY,"
                    + "id TEXT,"
                    + "parentZoneId TEXT, "
                    + "wording TEXT, "
                    + "filter TEXT, "
                    + "filter2 TEXT, "
                    + "damageCategoryId TEXT, "
                    + "_order TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + ZoneElement.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + ZoneElement.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<ZoneElement> {
        throw new NotImplementedError();
    }

    public getTableName(): string {
        return ZoneElement.TABLENAME;
    }

    protected rowToModel(row: any): ZoneElement {
        let zoneElement = new ZoneElement();

        zoneElement.id = row.id;
        zoneElement.parentZoneId = row.parentZoneId;
        zoneElement.wording = row.wording;
        zoneElement.filter = row.filter;
        zoneElement.filter2 = row.filter2;
        zoneElement.order = row._order;

        zoneElement.damageCategory.id = row.damageCategoryId;

        return zoneElement;
    }

    public save(zoneElement: ZoneElement): Promise<ZoneElement> {
        let promises = [];

        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + ZoneElement.TABLENAME + " "
                        + "(key, id, parentZoneId, wording, filter, filter2, damageCategoryId, _order) VALUES ("
                        + this.getValue(zoneElement.id + "_" + zoneElement.parentZoneId)
                        + this.getValue(zoneElement.id)
                        + this.getValue(zoneElement.parentZoneId)
                        + this.getValue(zoneElement.wording)
                        + this.getValue(zoneElement.filter)
                        + this.getValue(zoneElement.filter2)
                        + this.getFkValue(zoneElement.damageCategory)
                        + this.getValue(zoneElement.order, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return zoneElement;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }
}
